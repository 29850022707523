import type { MouseEvent } from 'react';
import React, { useCallback, useState } from 'react';
import type { FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import Loader from '@appchoose/loader';

import { MediumArrow } from '../icons/medium-arrow';
import { MediumError } from '../icons/medium-error';
import { MediumPdf } from '../icons/medium-pdf';
import { MediumPlus } from '../icons/medium-plus';
import { useFileUpload } from './use-upload-file';

type FileUploadProps = {
  orderId: string;
  file: FileWithPath | undefined;
  onFileChange: (files: FileWithPath[]) => void;
  onFileCleared: () => void;
  onFileUploaded: ({ key, url }: { key: string; url: string }) => void;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  orderId,
  file,
  onFileChange,
  onFileCleared,
  onFileUploaded,
}: FileUploadProps) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync } = useFileUpload({ orderId });

  const onDrop = useCallback(async (acceptedFiles: FileWithPath[]) => {
    setError(undefined);
    setIsLoading(true);
    if (acceptedFiles.length && acceptedFiles[0]) {
      onFileChange(acceptedFiles);
      const fileUploadResult = await mutateAsync({ file: acceptedFiles[0] });
      onFileUploaded(fileUploadResult);
    }
    setIsLoading(false);
  }, []);

  const onClearFile = () => {
    onFileChange([]);
    onFileCleared();
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
    open,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    maxFiles: 1,
    onDrop: onDrop,
  });

  let content = (
    <div className="flex flex-col items-center space-y-4">
      <MediumPlus className="text-gray-900" />
      <Button
        type="button"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          open();
        }}
      >
        {t('file_upload.add_file')}
      </Button>
      <p className="text-sm text-gray-500">
        {t('file_upload.or_drop_the_file_to_import')}
      </p>
    </div>
  );

  if (error) {
    content = (
      <div className="flex flex-col items-center space-y-4">
        <MediumError className="text-gray-900" />
        {error ? (
          Array.isArray(error) ? (
            <p className="text-xs text-gray-700">{error[0]}</p>
          ) : (
            <p className="text-xs text-gray-700">{error}</p>
          )
        ) : null}
      </div>
    );
  }
  if (file) {
    content = (
      <div className="flex flex-col items-center space-y-4">
        <MediumPdf className="text-gray-900" />
        <p className="font-semibold text-gray-900">{file.path}</p>
        <button
          type="button"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            onClearFile();
          }}
          className="text-sm font-bold text-green-900"
        >
          {t('file_upload.delete')}
        </button>
      </div>
    );
    if (isLoading) {
      content = (
        <div className="flex flex-col items-center space-y-4">
          <Loader className="size-8" />
          <p className="font-semibold text-gray-900">{file.path}</p>
          <button
            type="button"
            onClick={() => onClearFile()}
            className="text-sm font-bold text-green-900"
          >
            {t('file_upload.delete')}
          </button>
        </div>
      );
    }
  }
  if (isDragActive) {
    content = (
      <div className="flex flex-col items-center space-y-4">
        <MediumError className="text-gray-900" />
        <p className="text-xs text-gray-700">
          {t('message_group.fields.file_upload.must_respect_format')}
        </p>
      </div>
    );
    if (isDragAccept) {
      content = (
        <div className="flex flex-col items-center space-y-4">
          <MediumArrow className="text-gray-900" />
          <p className="text-xs text-gray-700">
            {t('file_upload.release_to_import')}
          </p>
        </div>
      );
    }
  }

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center rounded bg-[#FBFBFB] p-6',
        {
          'border-red-350 bg-red-300 hover:bg-red-300': error,
        }
      )}
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%238A8D8FFF' stroke-width='1' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }}
      data-active={isDragActive}
      data-rejected={isDragReject}
      {...getRootProps()}
    >
      <input id="file-upload" {...getInputProps()} />
      {content}
    </div>
  );
};
