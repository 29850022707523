import type { Dictionary } from '../types/dictionary';

/**
 * Checks if an ElasticSearch id is a perfect match.
 * @param {string} highlightElastic The highlighted ElasticSearch value to check against.
 * @returns {boolean}
 */
export const isHighlightPerfectMatchId = (
  highlightElastic: string
): boolean => {
  const regexpStartsWithEmphasis = /^<em>/i;
  if (!regexpStartsWithEmphasis.exec(highlightElastic)) {
    return false;
  }

  const regexpEndsWithEmphasis = /<\/em>$/i;
  if (!regexpEndsWithEmphasis.exec(highlightElastic)) {
    return false;
  }

  const highlightElasticDisemphasisUngreedy = highlightElastic
    .replace(/(<em>)+/i, '')
    .replace(/(<\/em>)+/i, '');
  const regexpRemainingEmphasis = /<\/?em>/i;

  return !regexpRemainingEmphasis.exec(highlightElasticDisemphasisUngreedy);
};

/**
 * Cleans an ElasticSearch emphased value.
 * @param {string} value The ElasticSearch value to clean.
 * @returns {boolean}
 */
export const sanitizeEmphasedElasticSearchResult = (value: string): string => {
  return value.replace(/(<\/?em>)+/gi, '');
};

export type ElasticSearchHighlights = Dictionary<{ value: string }> | undefined;
