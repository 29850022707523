import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';

import type { Email } from '../../types/generated';
import { transformDateWithYear } from '../../utils/date';

type ConversationSummaryEmailProps = {
  message: Email;
  selectEmail: (email: string) => void;
  activeId: string;
};

export const ConversationSummaryEmail: React.FC<
  ConversationSummaryEmailProps
> = ({ message, selectEmail, activeId }: ConversationSummaryEmailProps) => {
  const { i18n } = useTranslation();

  const selectConversation = () => {
    selectEmail(message.email ?? '');
  };

  const isActive = () => message.email && activeId === message.email;

  return (
    <li
      onClick={selectConversation}
      className={`cursor-pointer p-4 hover:bg-gray-50 ${
        isActive() ? 'bg-gray-50' : ''
      }`}
    >
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center truncate">
          {message.email ? (
            <Icon icon="mail" className="mr-1 shrink-0" />
          ) : null}
          <h5 className="truncate text-sm font-bold text-gray-900">
            <span>{message.email ?? ''}</span>
          </h5>
        </div>
        {message.createdAt ? (
          <time
            dateTime={message.createdAt}
            className="ml-4 shrink-0 text-xs text-gray-500"
          >
            {transformDateWithYear(
              new Date(message.createdAt),
              i18n.language === 'fr' ? 'fr' : 'en'
            )}
          </time>
        ) : null}
      </div>
      <div className="flex items-start justify-between">
        <p className="mb-1 line-clamp-2 min-h-8 w-[11.25rem] text-xs text-gray-700">
          {message.body}
        </p>
      </div>
    </li>
  );
};

ConversationSummaryEmail.displayName = 'ConversationSummaryEmail';
