import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import type { FormAddress } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import Flag from '@appchoose/flag';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import ToggleSwitch from '@appchoose/toggle-switch';
import * as Accordion from '@radix-ui/react-accordion';
import { useAtomValue, useSetAtom } from 'jotai';

import { imageViewerState } from '../../stores/imageViewerState';
import { ordersState } from '../../stores/orders';
import type { ThreadMessageGroup } from '../../stores/selected';
import type { ClaimItemInput } from '../../types/generated';
import { ClaimReason, useOpenClaimMutation } from '../../types/generated';
import {
  getClaimReason,
  getClaimReasonFromFlow,
  getDefaultClaimReasonTemplate,
} from '../../utils/utils';
import { SimpleTextArea } from '../simple-textarea/simple-textarea';
import { ProductDetail } from './product-detail';

type QuickOpenClaimModalProps = {
  message: ThreadMessageGroup;
  onClose: (isOpen: boolean) => void;
  onCloseAndOpenClaim: () => void;
};

type OpenClaimForm = {
  reason: ClaimReason;
  message: string;
  photos: string[];
  products: ClaimItemInput[];
  hasAddressChanged: boolean;
} & FormAddress;

export const QuickOpenClaimModal: React.FC<QuickOpenClaimModalProps> = ({
  message,
  onClose,
  onCloseAndOpenClaim,
}: QuickOpenClaimModalProps) => {
  const { i18n, t } = useTranslation();

  const orders = useAtomValue(ordersState);
  const setImageViewer = useSetAtom(imageViewerState);
  const [customerMessageAccordionValue, setCustomerMessageAccordionValue] =
    useState<string | undefined>(undefined);
  const claimReason = getClaimReasonFromFlow(message.reason);
  const linkedOrder = orders.find((order) => order.id === message.order.id);

  const [openClaimMutation, { loading: openClaimLoading }] =
    useOpenClaimMutation();

  const defaultClaimReasonTemplate = getDefaultClaimReasonTemplate(
    claimReason,
    linkedOrder?.seller?.isFrench ? 'fr' : 'en',
    t
  );
  const form = useForm<OpenClaimForm>({
    mode: 'onTouched',
    defaultValues: {
      reason: claimReason,
      message: defaultClaimReasonTemplate,
      photos: message.photos,
      products: message.order.itemsConcern?.map((item) => ({
        productId: item.productId,
        productVariantId: item.variantId,
      })),

      firstname: linkedOrder?.recipient.firstName,
      lastname: linkedOrder?.recipient.lastName,
      phone: linkedOrder?.recipient.phoneNumber,
      street: linkedOrder?.recipient.address.street,
      street2: linkedOrder?.recipient.address.streetAdditional ?? undefined,
      city: linkedOrder?.recipient.address.city,
      bp: linkedOrder?.recipient.address.zipCode,
      province: linkedOrder?.recipient.address.county ?? undefined,
      countryCode: linkedOrder?.recipient.address.countryCode,
      country: linkedOrder?.recipient.address.country,
    },
  });

  const onSubmit = async (data: OpenClaimForm) => {
    try {
      await openClaimMutation({
        variables: {
          orderId: message.order.id,
          input: {
            reason: data.reason,
            messageFromSupport: data.message,
            proofUrls: data.photos,
            items: data.products,
            reshipShippingAddress: data.hasAddressChanged
              ? {
                  firstname: data.firstname ?? '',
                  lastname: data.lastname ?? '',
                  street: data.street,
                  streetAdditional: data.street2,
                  city: data.city,
                  zipCode: data.bp,
                  county: data.province,
                  //country: data.country,
                  countryCode: data.countryCode,
                  phoneNumber: data.phone ?? '',
                }
              : undefined,
          },
        },
      });

      toast.success(
        t('claim.open_claim.success', {
          brand_name: message.order.brandName,
        }),
        {
          duration: 6000,
        }
      );

      onClose(false);
    } catch {
      toast.error(t('claim.open_claim.error'));
    }
  };

  const scrollToAddress = () => {
    const addressElement = document.getElementById('shipping-address');
    if (addressElement) {
      setTimeout(() => {
        addressElement.scrollIntoView({
          block: 'start',
          inline: 'nearest',
        });
      }, 50);
    }
  };

  form.watch('hasAddressChanged');

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          <h3
            id="modal-quick-open-claim-title"
            className="mb-6 text-2xl font-bold text-gray-900"
          >
            {t('quick_open_claim_modal.title')}
          </h3>
          <div className="mb-6 text-sm text-gray-700">
            <Trans
              t={t}
              i18nKey="quick_open_claim_modal.description"
              values={{ brand_name: message.order.brandName }}
              components={{ b: <b className="text-gray-900" /> }}
            />
          </div>
          <div>
            <div className="grid grid-cols-[200px,_auto] gap-8 border-b border-gray-100 pb-6 last:border-none">
              <div className="text-sm text-gray-600">
                <div className="flex items-center space-x-2">
                  <Icon icon="complains" />
                  <span>{t('quick_open_claim_modal.reason')}</span>
                </div>
              </div>
              <div className="text-xl font-bold">
                {getClaimReason(claimReason, t)}
              </div>
            </div>
            {(message.order.itemsConcern?.length ?? 0) > 0 ? (
              <div className="grid grid-cols-[200px,_auto] gap-8 border-b border-gray-100 pb-6 pt-4 last:border-none">
                <div className="text-sm text-gray-600">
                  <div className="flex items-center space-x-2">
                    <Icon icon="orders" />
                    <div className="space-x-1">
                      <span>
                        {t('quick_open_claim_modal.item', {
                          count: message.order.itemsConcern?.length ?? 0,
                        })}
                      </span>
                      <span className="font-bold text-gray-900">
                        ({message.order.itemsConcern?.length})
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {message.order.itemsConcern?.map((item, idx) => (
                    <ProductDetail
                      key={idx}
                      item={{
                        ...item,
                        sku:
                          linkedOrder?.items.find(
                            (i) =>
                              i.productId === item.productId &&
                              i.productVariantId == item.variantId
                          )?.sku ?? undefined,
                        gtin:
                          linkedOrder?.items.find(
                            (i) =>
                              i.productId === item.productId &&
                              i.productVariantId == item.variantId
                          )?.gtin ?? undefined,
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            {message.photos.length > 0 ? (
              <div className="grid grid-cols-[200px,_auto] gap-8 border-b border-gray-100 pb-6 pt-4 last:border-none">
                <div className="text-sm text-gray-600">
                  <div className="flex items-center space-x-2">
                    <Icon icon="camera" />
                    <div className="space-x-1">
                      <span>
                        {t('quick_open_claim_modal.photo', {
                          count: message.photos.length,
                        })}
                      </span>
                      <span className="font-bold text-gray-900">
                        ({message.photos.length})
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-4">
                  {message.photos.map((photo, idx) => (
                    <img
                      key={idx}
                      src={photo}
                      onClick={() =>
                        setImageViewer({
                          slides: message.photos.map((photo, index) => ({
                            index: index,
                            src: photo,
                          })),
                          initialIndex: idx,
                        })
                      }
                      className="h-32 cursor-pointer rounded"
                    />
                  ))}
                </div>
              </div>
            ) : null}
            <div className="grid grid-cols-[200px,_auto] gap-8 border-b border-gray-100 pb-6 pt-4 last:border-none">
              <div className="text-sm text-gray-600">
                <div className="mb-2 flex items-center space-x-2">
                  <Icon icon="chat" />
                  <span>{t('quick_open_claim_modal.message_for_brand')}</span>
                </div>
                <div>
                  <Accordion.Root
                    type="single"
                    collapsible
                    defaultValue="customer-message"
                  >
                    <Accordion.Item value="customer-message">
                      <Accordion.Header>
                        <Accordion.Trigger className="flex w-full space-x-2 py-2 text-left text-xs text-green-900 [&[data-state=open]>svg]:rotate-180">
                          <span>
                            {t('quick_open_claim_modal.message_from_customer')}
                          </span>
                          <Icon
                            icon="arrowDown"
                            className="text-green-900 transition-transform"
                          />
                        </Accordion.Trigger>
                      </Accordion.Header>
                      <Accordion.Content>
                        <div className="mb-2 text-xs text-gray-500">
                          {t('quick_open_claim_modal.message_only_you')}
                        </div>
                        <div className="rounded-xl rounded-tl-none bg-gray-50 p-4 text-xs text-gray-700">
                          {message.body}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                  </Accordion.Root>
                </div>
              </div>
              <div>
                {!linkedOrder?.seller?.isFrench ? (
                  <p className="mb-2 flex items-center space-x-2 text-sm text-gray-600">
                    <span>
                      {t('claim.open_claim.step_message.brand_speaks_english')}
                    </span>
                    <Flag flag="US" className="h-4 w-4" />
                  </p>
                ) : null}
                <FormField
                  control={form.control}
                  name="message"
                  rules={{
                    required: true,
                    validate: {
                      isNotDirty: (value) =>
                        // TODO: we don't handle this case with quick open for now
                        /*claimReason ===
                ClaimReason.DigitalCouponCodeNotWorking ||*/
                        claimReason ===
                          ClaimReason.NotDeliveredAndTrackingBlocked ||
                        value !== defaultClaimReasonTemplate,
                    },
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">
                        {t(
                          'claim.open_claim.step_message.fields.message.label'
                        )}
                      </FormLabel>
                      <FormControl>
                        <SimpleTextArea
                          placeholder={t(
                            'claim.open_claim.step_message.fields.message.placeholder'
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage match="required">
                        {t(
                          'claim.open_claim.step_message.fields.message.validation_errors.required'
                        )}
                      </FormMessage>
                      <FormMessage match="isNotDirty">
                        {t(
                          'claim.open_claim.step_message.fields.message.validation_errors.is_not_dirty'
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-[200px,_auto] gap-8 border-b border-gray-100 pt-4 last:border-none">
              <div className="text-sm text-gray-600">
                <div className="mb-2 flex items-center space-x-2">
                  <Icon icon="place" />
                  <span id="shipping-address">
                    {t('quick_open_claim_modal.shipping_address')}
                  </span>
                </div>
                <div>
                  <Accordion.Root
                    type="single"
                    collapsible
                    value={customerMessageAccordionValue}
                    onValueChange={(value) =>
                      setCustomerMessageAccordionValue(value)
                    }
                  >
                    <Accordion.Item value="customer-message">
                      <Accordion.Header>
                        <Accordion.Trigger className="flex w-full space-x-2 py-2 text-left text-xs text-green-900 [&[data-state=open]>svg]:rotate-180">
                          <span>
                            {t('quick_open_claim_modal.message_from_customer')}
                          </span>
                          <Icon
                            icon="arrowDown"
                            className="text-green-900 transition-transform"
                          />
                        </Accordion.Trigger>
                      </Accordion.Header>
                      <Accordion.Content>
                        <div className="mb-2 text-xs text-gray-500">
                          {t('quick_open_claim_modal.message_only_you')}
                        </div>
                        <div className="rounded-xl rounded-tl-none bg-gray-50 p-4 text-xs text-gray-700">
                          {message.body}
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                  </Accordion.Root>
                </div>
              </div>
              <div className="text-sm text-gray-900">
                <div className="mb-6">
                  <FormField
                    control={form.control}
                    name="hasAddressChanged"
                    render={({ field: { value, onChange } }) => (
                      <div className="flex items-center space-x-2">
                        <ToggleSwitch
                          checked={value}
                          onCheckedChange={(checked) => {
                            onChange(checked);
                            if (checked) {
                              setCustomerMessageAccordionValue(
                                'customer-message'
                              );

                              // Waiting for the address to load
                              setTimeout(() => {
                                scrollToAddress();
                              }, 250);
                            } else {
                              setCustomerMessageAccordionValue(undefined);
                            }
                          }}
                        />
                        <span>
                          {t(
                            'claim.open_claim.step_message.send_to_new_shipping_address'
                          )}
                        </span>
                      </div>
                    )}
                  />
                </div>
                {form.getValues('hasAddressChanged') ? (
                  <AddressFormFields
                    googleMapsApiKey={
                      import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
                    }
                    locale={i18n.language}
                    showCompany={false}
                    showName={true}
                    showPhone={true}
                    translations={{
                      suggestions: t('suggestions'),
                      fieldsNameValidationErrorsRequired: t(
                        'address.fields.name.validation_errors.required'
                      ),
                      fieldsNameValidationErrorsMaxLength: t(
                        'address.fields.name.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsFirstnameValidationErrorsRequired: t(
                        'address.fields.firstname.validation_errors.required'
                      ),
                      fieldsFirstnameValidationErrorsMaxLength: t(
                        'address.fields.firstname.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsLastnameValidationErrorsRequired: t(
                        'address.fields.lastname.validation_errors.required'
                      ),
                      fieldsLastnameValidationErrorsMaxLength: t(
                        'address.fields.lastname.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsPhoneValidationErrorsRequired: t(
                        'address.fields.phone.validation_errors.required'
                      ),
                      fieldsPhoneValidationErrorsMaxLength: t(
                        'address.fields.phone.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsStreetValidationErrorsRequired: t(
                        'address.fields.street.validation_errors.required'
                      ),
                      fieldsStreetValidationErrorsMaxLength: t(
                        'address.fields.street.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsStreet2ValidationErrorsMaxLength: t(
                        'address.fields.street2.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsBpValidationErrorsRequired: t(
                        'address.fields.bp.validation_errors.required'
                      ),
                      fieldsBpValidationErrorsMaxLength: t(
                        'address.fields.bp.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsCityValidationErrorsRequired: t(
                        'address.fields.city.validation_errors.required'
                      ),
                      fieldsCityValidationErrorsMaxLength: t(
                        'address.fields.city.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsProvinceValidationErrorsRequired: t(
                        'address.fields.province.validation_errors.required'
                      ),
                      fieldsProvinceValidationErrorsMaxLength: t(
                        'address.fields.province.validation_errors.maxLength',
                        {
                          maxLength: '200',
                        }
                      ),
                      fieldsCountryValidationErrorsRequired: t(
                        'address.fields.country.validation_errors.required'
                      ),
                      fieldsNameLabel: t('address.fields.name.label_return'),
                      fieldsNamePlaceholder: t(
                        'address.fields.name.placeholder'
                      ),
                      fieldsFirstnameLabel: t('address.fields.firstname.label'),
                      fieldsFirstnamePlaceholder: t(
                        'address.fields.firstname.placeholder'
                      ),
                      fieldsLastnameLabel: t('address.fields.lastname.label'),
                      fieldsLastnamePlaceholder: t(
                        'address.fields.lastname.placeholder'
                      ),
                      fieldsPhoneLabel: t('address.fields.phone.label'),
                      fieldsPhonePlaceholder: t(
                        'address.fields.phone.placeholder'
                      ),
                      fieldsStreetLabel: t('address.fields.street.label'),
                      fieldsStreetPlaceholder: t(
                        'address.fields.street.placeholder'
                      ),
                      fieldsStreet2Label: t('address.fields.street2.label'),
                      fieldsStreet2Placeholder: t(
                        'address.fields.street2.placeholder'
                      ),
                      fieldsBpLabel: t('address.fields.bp.label'),
                      fieldsBpPlaceholder: '',
                      fieldsCityLabel: t('address.fields.city.label'),
                      fieldsCityPlaceholder: '',
                      fieldsProvinceLabel: t('address.fields.province.label'),
                      fieldsProvincePlaceholder: '',
                      fieldsProvinceEmptyField: t(
                        'address.fields.province.empty_field'
                      ),
                      fieldsCountryLabel: t('address.fields.country.label'),
                      fieldsCountryPlaceholder: t(
                        'address.fields.country.placeholder'
                      ),
                      fieldsCountryPlaceholderSearch: t(
                        'address.fields.country.placeholder_search'
                      ),
                      fieldsCountryEmptyField: t(
                        'address.fields.country.empty_field'
                      ),
                      fieldsCountryNoResults: t(
                        'address.fields.country.no_results'
                      ),
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
          <Button
            type="button"
            className="border-none text-green-900"
            appearance="outline"
            onClick={() => onClose(false)}
            disabled={openClaimLoading}
            tabIndex={-1}
          >
            {t('cancel')}
          </Button>
          <Button
            type="button"
            appearance="outline"
            onClick={() => onCloseAndOpenClaim()}
            disabled={openClaimLoading}
          >
            {t('quick_open_claim_modal.actions.edit')}
          </Button>
          <Button
            appearance="primary"
            type="submit"
            disabled={openClaimLoading}
          >
            {t('quick_open_claim_modal.actions.confirm_and_send')}
          </Button>
        </footer>
      </form>
    </Form>
  );
};
