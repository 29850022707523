import React from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';

import type { Item } from '../../types/order';

export type ProductViewProductDetailsProps = {
  item: Item;
};

export const ProductViewProductDetails: React.FC<
  ProductViewProductDetailsProps
> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-start space-x-3">
      <img
        src={item.imageUrl}
        alt=""
        className="size-14 rounded-lg border-2 border-white object-contain"
      />
      <div className="space-y-1">
        <p className="text-sm text-gray-900">{item.name}</p>
        {item.size ? (
          <p className="text-xs text-gray-700">
            {t('order.size')} <span>{item.size}</span>
          </p>
        ) : null}
        {item.sku ? (
          <p className="text-xs text-gray-700">
            {t('order.sku')} <span>{item.sku}</span>
          </p>
        ) : null}
        {item.gtin ? (
          <p className="text-xs text-gray-700">
            {t('order.gtin')} <span>{item.gtin}</span>
          </p>
        ) : null}
        {(item.digitalCouponCode ?? item.newDigitalCouponCode) ? (
          <span className="break-all text-xs uppercase text-gray-700">
            {item.newDigitalCouponCode ?? item.digitalCouponCode}
          </span>
        ) : null}
        {!item.isReturnable ? (
          <p className="text-xs text-orange-600">
            {t('order.product_not_eligible_for_return')}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export type ProductViewContentProps = {
  children: React.ReactNode;
};

export const ProductViewContent: React.FC<ProductViewContentProps> = ({
  children,
}) => {
  return (
    <div className="flex items-start justify-between space-x-3">{children}</div>
  );
};

export type ProductViewProps = {
  children: React.ReactNode;
  className?: string;
  isSelected: boolean;
  isDisabled: boolean;
};

export const ProductView: React.FC<ProductViewProps> = ({
  children,
  className,
  isSelected,
  isDisabled,
}: ProductViewProps) => {
  return (
    <div
      className={cn(
        'relative flex flex-col justify-center rounded p-4 transition duration-300 focus-within:ring-2 focus-within:ring-opacity-30',
        className,
        {
          'border-2 border-green-900 focus-within:ring-green-900':
            isSelected && !isDisabled,
          'border border-gray-900 focus-within:ring-gray-900 hover:border-gray-900':
            !isSelected && !isDisabled,
          'cursor-not-allowed border border-gray-300': isDisabled,
          'cursor-pointer': !isDisabled,
        }
      )}
    >
      {children}
    </div>
  );
};
