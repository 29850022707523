import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import type { Email, Message } from '../types/generated';
import { SortType } from '../types/generated';

export const messagesState = atomWithReset<Message[] | undefined>(undefined);

export const emailsState = atomWithReset<Email[] | undefined>(undefined);

export const sortState = atom<SortType>(SortType.Asc);

export const messagesOrEmailsDataState = atom((get) => {
  const messages = get(messagesState);
  const emails = get(emailsState);
  const sort = get(sortState);

  switch (sort) {
    case SortType.Asc:
      return [...(messages ?? []), ...(emails ?? [])].slice().sort((a, b) => {
        return new Date(a.createdAt ?? '').getTime() <
          new Date(b.createdAt ?? '').getTime()
          ? -1
          : 1;
      });
    case SortType.Desc:
      return [...(messages ?? []), ...(emails ?? [])].slice().sort((a, b) => {
        return new Date(a.createdAt ?? '').getTime() >
          new Date(b.createdAt ?? '').getTime()
          ? -1
          : 1;
      });
    case SortType.AiScoring:
    default:
      return [...(messages ?? []), ...(emails ?? [])].slice();
  }
});
