import React, { useState } from 'react';

import Button from '@appchoose/button';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { t } from 'i18next';
import { useAtomValue } from 'jotai';

import { NewTemplateFolder } from '../../components/new-template-folder/new-template-folder';
import { NewTemplate } from '../../components/new-template/new-template';
import { templatesMapState } from '../../stores/templates';
import type { TemplateData } from '../../types/generated';
import { deepCopy } from '../../utils/object';

import './Templates-Screen.scss';

export const TemplatesScreen: React.FC = () => {
  const [displayModalCategory, setDisplayModalCategory] = useState(false);
  const [displayModalTemplate, setDisplayModalTemplate] = useState(false);
  const [selected, setSelected] = useState<TemplateData | undefined>(undefined);

  const handleChange = (category: TemplateData) => {
    setSelected(deepCopy<TemplateData>(category));

    setDisplayModalTemplate(true);
  };

  const handleNewTemplate = () => {
    setSelected(undefined);
  };

  const templatesMap = useAtomValue(templatesMapState);

  return (
    <div className="template-screen">
      <div className="flex items-center justify-between">
        <h2 className="font-bold text-gray-900">{t('templates.templates')}</h2>
        <div className="flex space-x-2">
          <Modal
            open={displayModalCategory}
            onOpenChange={setDisplayModalCategory}
          >
            <ModalTrigger asChild>
              <Button type="button" appearance="outline">
                {t('templates.template_category.new_folder')}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <NewTemplateFolder setIsOpen={setDisplayModalCategory} />
            </ModalContent>
          </Modal>
          <Modal
            open={displayModalTemplate}
            onOpenChange={setDisplayModalTemplate}
          >
            <ModalTrigger asChild>
              <Button
                type="button"
                onClick={handleNewTemplate}
                appearance="primary"
              >
                {t('templates.template.create_new_template')}
              </Button>
            </ModalTrigger>
            <ModalContent scrollable>
              <NewTemplate
                setIsOpen={setDisplayModalTemplate}
                existingTemplate={selected}
              />
            </ModalContent>
          </Modal>
        </div>
      </div>

      <div className="container">
        {templatesMap.length > 0
          ? templatesMap.map((template) => {
              return (
                <div key={template.key}>
                  <h3 className="font-bold">{template.name}</h3>
                  {template.data.map((c) => {
                    return (
                      <button
                        type="button"
                        className="mb-2 block text-left"
                        key={c.key}
                        onClick={handleChange.bind(this, c)}
                      >
                        <h5 className="font-bold">{c.title}</h5>
                        <p>{c.message}</p>
                      </button>
                    );
                  })}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};
