import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMiniSearch } from 'react-minisearch';

import Checkbox from '@appchoose/checkbox';
import { FormField } from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input, { InputAdornment } from '@appchoose/input';

import type { SearchProductIndex } from '../../utils/product';
import { SEARCH_PRODUCT_CONFIG, getSearchQuery } from '../../utils/product';
import {
  ProductView,
  ProductViewContent,
  ProductViewProductDetails,
} from '../product-view/product-view';
import type { OtherActionsForm } from './other-actions-modal';

export const OtherActionsModalDeleteProductsFormFields = () => {
  const { t } = useTranslation();

  const [productSearch, setProductSearch] = useState('');
  const form = useFormContext<OtherActionsForm>();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'products',
  });
  const { search, searchResults } = useMiniSearch<SearchProductIndex>(
    form.getValues('products').map((p) => ({
      id: p.product.id,
      variant_id: p.product.productVariantId,
      product_id: p.product.productId,
      name: p.product.name,
      gtin: p.product.gtin,
      sku: p.product.sku,
    })),
    SEARCH_PRODUCT_CONFIG
  );

  useEffect(() => {
    search({
      prefix: true,
      queries: getSearchQuery(productSearch),
    });
  }, [search, productSearch]);

  const ProductsErrorMessage =
    form.formState.errors.products
      ?.filter?.((s) => s !== undefined)
      .map((s) => s.selected?.type)
      .find((t) => t === 'atLeastOne') &&
    t(
      'other_actions.step_params.fields.products.validation_errors.at_least_one'
    );

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="search-products" className="sr-only">
          {t('other_actions.delete_articles.search_product.label')}
        </label>
        <Input
          id="search-products"
          type="text"
          value={productSearch}
          onChange={(e) => setProductSearch(e.target.value)}
          placeholder={t(
            'other_actions.delete_articles.search_product.placeholder'
          )}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon="search" />
            </InputAdornment>
          }
          endAdornment={
            productSearch ? (
              <InputAdornment position="end">
                <button type="button" onClick={() => setProductSearch('')}>
                  <Icon icon="close" />
                </button>
              </InputAdornment>
            ) : null
          }
        />
      </div>

      <div className="flex flex-wrap justify-center gap-2">
        {fields.map((field, index) => (
          <label
            key={field.id}
            className={`w-full ${
              productSearch &&
              !searchResults?.find((value) => value.id === field.product.id) &&
              'hidden'
            }`}
          >
            <ProductView
              isSelected={field.selected}
              isDisabled={!field.product.isCancellable}
            >
              <ProductViewContent>
                <ProductViewProductDetails item={field.product} />
                <div className="flex h-14 items-center">
                  <FormField
                    control={form.control}
                    name={`products.${index}.selected`}
                    rules={{
                      validate: {
                        atLeastOne: () =>
                          form.getValues('products').filter((s) => s.selected)
                            .length > 0,
                      },
                    }}
                    disabled={!field.product.isCancellable}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        value=""
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </ProductViewContent>
            </ProductView>
          </label>
        ))}
        {productSearch && searchResults?.length === 0 && (
          <p className="mt-12 text-sm text-gray-600">
            {t('other_actions.delete_articles.no_result')}
          </p>
        )}
      </div>
      <div className="mt-2 text-xs text-red-600">{ProductsErrorMessage}</div>
    </div>
  );
};
