import { useCallback, useEffect, useState } from 'react';

import type { IdTokenResult } from 'firebase/auth';

import type { ChooseClaims } from '../stores/authcontext';
import { useUser } from '../stores/usercontext';

export const useUserClaims = () => {
  const [claims, setClaims] = useState<ChooseClaims | null>(null);
  const { user } = useUser();

  const getTokenResult = useCallback(
    (callback: (idTokenResult: IdTokenResult) => void) => {
      return user?.getIdTokenResult().then((token) => {
        callback(token);
      });
    },
    [user]
  );

  useEffect(() => {
    getTokenResult((tokenResult) => {
      setClaims(tokenResult.claims as unknown as ChooseClaims);
    });
  }, [getTokenResult]);

  return claims;
};
