import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';

import type { ConversationEmail } from '../../types/generated';
import { transformDateMessage, transformHours } from '../../utils/date';

type EmailMessageProps = {
  message: ConversationEmail;
};

export const EmailMessage: React.FC<EmailMessageProps> = ({
  message,
}: EmailMessageProps) => {
  const { i18n } = useTranslation();

  return (
    <li
      className={cn(`message space-y-2`, {
        choose: message.fromChoose,
      })}
    >
      {message.createdAt ? (
        <p className="timer mb-4 text-center text-xs text-gray-500">
          {transformDateMessage(
            new Date(message.createdAt),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </p>
      ) : null}
      <p
        className={cn('body rounded-xl p-4 text-sm', {
          'rounded-br-sm bg-green-900 text-white': message.fromChoose,
          'rounded-tl-sm border border-gray-100 bg-[#FBFBFB] text-gray-700':
            !message.fromChoose,
        })}
      >
        {message.body}
      </p>
      {message.createdAt ? (
        <p
          className={cn('timer text-xs text-gray-500', {
            'text-right': message.fromChoose,
          })}
        >
          {transformHours(
            new Date(message.createdAt),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </p>
      ) : null}
    </li>
  );
};
