import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AddressView from '@appchoose/address-view';
import { toast } from '@appchoose/toast';

import { useGetProductInfoQuery } from '../../types/generated';
import { transformDateSimple } from '../../utils/date';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../tabs';

type ProductModalProps = {
  productId: string | undefined;
};

export const ProductModal: React.FC<ProductModalProps> = ({
  productId,
}: ProductModalProps) => {
  const { i18n, t } = useTranslation();

  const { data: productInfoData, error: productInfoError } =
    useGetProductInfoQuery({
      variables: { id: productId ?? '' },
      skip: !productId,
    });
  const productSelected = productInfoData?.getProductInfo
    ? {
        ...productInfoData.getProductInfo,
        image: productInfoData.getProductInfo.image?.replace('[W]x[H]', 'x144'),
      }
    : undefined;

  useEffect(() => {
    if (productInfoError) {
      toast.error('Une erreur est survenue lors du chargement du produit');
    }
  }, [productInfoError]);

  if (!productSelected) {
    return null;
  }

  const renderContent = () => {
    return (
      <div className="flex space-x-8">
        <img
          alt="product"
          src={productSelected.image ?? ''}
          className="h-36 rounded border-0.5 border-gray-300 object-cover"
        />
        <div>
          <h3 className="my-4 text-xl font-bold text-gray-700">
            {productSelected.name}
          </h3>
          <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('product.tabs.product_info.product_details')}
          </h4>
          <p className="whitespace-pre-line text-sm text-gray-700">
            {productSelected.description}
          </p>
          {productSelected.is_coupon ? (
            <>
              <p className="mt-10 whitespace-pre-line text-sm text-gray-700">
                {t('product.tabs.product_info.availability')}{' '}
                <span className="font-semibold">
                  {productSelected.coupon_expire_at
                    ? transformDateSimple(
                        new Date(productSelected.coupon_expire_at),
                        i18n.language === 'fr' ? 'fr' : 'en',
                        'P'
                      )
                    : t('product.tabs.product_info.no_expiration_date')}
                </span>
              </p>
              <h4 className="mb-1 mt-4 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {t('product.tabs.product_info.connect')}
              </h4>
              <ol className="ml-5 list-decimal whitespace-pre-line text-sm text-gray-700">
                {[
                  productSelected.coupon_faq_after_order,
                  ...productSelected.coupon_faq,
                ].map((coupon, index) => (
                  <li key={index}>{coupon}</li>
                ))}
              </ol>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const renderPolicy = () => {
    return (
      <div className="flex">
        <div>
          {productSelected.not_returnable ? (
            <p className="mb-4 text-sm font-bold text-orange-600">
              {t('product.tabs.return_policy.not_returnable')}
            </p>
          ) : null}
          <p>{productSelected.return_policy}</p>
          <h4 className="mb-1 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('product.tabs.return_policy.return_address')}
          </h4>
          {productSelected.return_address ? (
            <AddressView
              label={t('address.label')}
              size="default"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              address={productSelected.return_address}
              locale={i18n.language === 'fr' ? 'FR' : 'EN'}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const renderCGU = () => {
    return (
      <div className="flex">
        <div>
          <h4 className="mb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('product.tabs.cgu.terms_of_use')}
          </h4>
          {productSelected.coupon_cgu ? (
            <div className="whitespace-pre-line text-sm text-gray-700">
              <p>{productSelected.coupon_cgu.join('\n')}</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const tabs = [
    {
      key: 'info',
      title: t('product.tabs.product_info.title'),
      content: renderContent(),
    },
    {
      key: 'policy',
      title: t('product.tabs.return_policy.title'),
      content: renderPolicy(),
    },
    productSelected?.is_coupon
      ? {
          key: 'cgu',
          title: t('product.tabs.cgu.title'),
          content: renderCGU(),
        }
      : null,
  ].filter((tab) => !!tab) as {
    key: string;
    title: string;
    content: React.ReactNode;
  }[];

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <Tabs
        defaultValue="info"
        className="flex h-full flex-col overflow-hidden rounded-lg"
      >
        <div className="sticky top-0 bg-white px-8 pt-4">
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger key={tab.key} value={tab.key}>
                {tab.title}
              </TabsTrigger>
            ))}
          </TabsList>
        </div>
        <div className="flex-auto overflow-y-auto px-8 pb-4">
          {tabs.map((tab) => (
            <TabsContent key={tab.key} value={tab.key}>
              {tab.content}
            </TabsContent>
          ))}
        </div>
      </Tabs>
    </div>
  );
};
