import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type ConversationEmail = {
  fromChoose: boolean;
  body: string;
  email: string;
  createdAt: string;
  isFixed: boolean;
  isPinned: boolean;
};

export const selectedEmailState = atomWithReset<
  ConversationEmail[] | undefined
>(undefined);

export const firstEmailFromSelectedEmailState = atom((get) => {
  const conversationEmails = get(selectedEmailState);
  return conversationEmails?.[0];
});

export const pinnedEmailsFromSelectedEmailState = atom((get) => {
  const conversationEmails = get(selectedEmailState);
  if (conversationEmails && conversationEmails.length > 0) {
    return conversationEmails.filter((c) => c.isPinned).length > 0;
  }
  return false;
});

export const fixedEmailsFromSelectedEmailState = atom((get) => {
  const conversationEmails = get(selectedEmailState);
  if (conversationEmails && conversationEmails.length > 0) {
    return conversationEmails.filter((c) => !c.isFixed).length === 0;
  }
  return false;
});
