import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CreditHistory } from '../../types/generated';
import { transformPrice } from '../../utils/currency';
import { transformDateSimple } from '../../utils/date';

import './credit-history-modal.scss';

type CreditHistoryProps = {
  credits: CreditHistory[];
};

export const CreditHistoryModal: React.FC<CreditHistoryProps> = ({
  credits,
}) => {
  const { i18n, t } = useTranslation();

  return (
    <div className="credit-modal-history flex h-full flex-col justify-between overflow-hidden">
      <div className="relative flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
        <h3
          id="modal-credit-history-title"
          className="text-2xl font-bold text-gray-900"
        >
          {t('credit_history.title')}
        </h3>
        <div>
          {credits.length > 0 ? (
            credits.map((credit, index: number) => {
              return (
                <div className="credit-elt" key={index}>
                  <p className="amount">
                    {transformPrice(
                      credit.credit.value || 0,
                      i18n.language === 'fr' ? 'fr' : 'en',
                      credit.credit.currency
                    )}{' '}
                    <span>{credit.description}</span>
                  </p>
                  <p className="text-xs text-gray-500">
                    {t('credit_history.added_on')}{' '}
                    {transformDateSimple(
                      new Date(credit.createdAt ?? ''),
                      i18n.language === 'fr' ? 'fr' : 'en'
                    )}{' '}
                    {credit.operator ? `par ${credit.operator}` : ''}
                  </p>
                </div>
              );
            })
          ) : (
            <p className="empty">{t('credit_history.no_active_credit')}</p>
          )}
        </div>
      </div>
    </div>
  );
};
