import React, { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { NotifyUpdate } from '@appchoose/notify';
import Toast from '@appchoose/toast';
import { TooltipProvider } from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';
import { t } from 'i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { client } from './api/apollo';
import './api/sentry';
import './lang/i18n';
import { AppRouting } from './routes/app-routing';
import { AuthProvider } from './stores/authcontext';

import './App.scss';

// interval between each call to the Service Worker
const intervalMS = 2 * 60 * 1000;

export const App: React.FC = () => {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(registration) {
      console.log('SW Registered: ', registration);
      return (
        registration &&
        setInterval(() => {
          if (registration.installing || !navigator) return;

          if ('connection' in navigator && !navigator.onLine) return;

          registration.update();
        }, intervalMS)
      );
    },
    onRegisterError(error) {
      Sentry.captureException(error);
    },
  });

  useEffect(() => {
    document.addEventListener('wheel', () => {
      const numberInput = document.activeElement as HTMLInputElement;
      if (numberInput.type === 'number') {
        numberInput.blur();
      }
    });
    document.addEventListener('keydown', (event) => {
      const numberInput = document.activeElement as HTMLInputElement;
      if (
        numberInput.type === 'number' &&
        (event.key === 'e' || event.key === 'E')
      ) {
        event.preventDefault();
      }
    });
  }, []);

  return (
    <Sentry.ErrorBoundary>
      <AuthProvider>
        <ApolloProvider client={client}>
          <TooltipProvider>
            <AppRouting />
            <Toast />
            {needRefresh && (
              <NotifyUpdate
                title={t('update.new_version')}
                description={t('update.refresh_page')}
                onClick={() => {
                  updateServiceWorker();
                  setNeedRefresh(false);
                }}
              />
            )}
          </TooltipProvider>
        </ApolloProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};
