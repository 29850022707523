import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Illustration from '@appchoose/illustration';

type SaleGroupMessageModalStepCompleteProps = {
  onClose: () => void;
};

export const SaleGroupMessageModalStepComplete: React.FC<
  SaleGroupMessageModalStepCompleteProps
> = ({ onClose }: SaleGroupMessageModalStepCompleteProps) => {
  const { t } = useTranslation();

  return (
    <div className="last-step flex size-full flex-col items-center justify-center">
      <div className="mb-2">
        <Illustration illustration="checked" />
      </div>
      <div className="mb-4 text-center text-2xl font-bold text-gray-900">
        {t('message_group.perfect')}
        <br />
        {t('message_group.message_being_sent')}
      </div>
      <p
        style={{ maxWidth: '424px' }}
        className="mb-6 text-center text-sm text-gray-700"
      >
        {t('message_group.may_take_a_few_minutes')}
      </p>
      <Button type="button" onClick={onClose}>
        {t('message_group.close')}
      </Button>
    </div>
  );
};
