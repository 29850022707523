import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';

import type { ThreadMessageGroup } from '../../stores/selected';
import { ClaimReason } from '../../types/generated';
import type { Claim } from '../../types/order';
import { transformDateSimple } from '../../utils/date';
import {
  getClaimReasonFromFlow,
  getRemainingTime,
  isClaimPending,
} from '../../utils/utils';
import { Check } from '../icons/check';
import { OpenClaim } from '../open-claim/open-claim';
import { QuickOpenClaimModal } from '../quick-open-claim-modal/quick-open-claim-modal';

type CountdownProps = {
  endDate: Date;
  className?: string;
} & React.ComponentPropsWithoutRef<'span'>;

export const Countdown: React.FC<CountdownProps> = ({
  endDate,
  className,
  ...props
}: CountdownProps) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(id);
  }, []);
  return (
    <span className={className} {...props}>
      {getRemainingTime(currentDate, endDate)}
    </span>
  );
};

type ChatMessageGroupFooterProps = {
  message: ThreadMessageGroup;
  claim?: Claim;
};

export const ChatMessageGroupFooter: React.FC<ChatMessageGroupFooterProps> = ({
  message,
  claim,
}: ChatMessageGroupFooterProps) => {
  const { i18n, t } = useTranslation();

  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [openQuickClaimModal, setOpenQuickClaimModal] = useState(false);

  if (!claim && !message.order.willBeOpenedByBotAt) {
    return (
      <>
        <Modal open={openClaimModal} onOpenChange={setOpenClaimModal}>
          <ModalTrigger asChild>
            <Button
              appearance="outline"
              type="button"
              className={`w-full justify-center ${
                getClaimReasonFromFlow(message.reason) !== ClaimReason.Other &&
                'hidden'
              }`}
            >
              {t('order.actions.open_claim')}
            </Button>
          </ModalTrigger>
          <ModalContent scrollable>
            <OpenClaim
              orderId={message.order.id}
              setIsOpen={() => setOpenClaimModal(false)}
            />
          </ModalContent>
        </Modal>
        <Modal open={openQuickClaimModal} onOpenChange={setOpenQuickClaimModal}>
          <ModalTrigger asChild>
            <Button
              appearance="outline"
              type="button"
              className={`w-full justify-center ${
                getClaimReasonFromFlow(message.reason) === ClaimReason.Other &&
                'hidden'
              }`}
            >
              {t('order.actions.open_claim')}
            </Button>
          </ModalTrigger>
          <ModalContent scrollable className="!max-h-[42rem] max-w-[55rem]">
            <QuickOpenClaimModal
              message={message}
              onClose={() => setOpenQuickClaimModal(false)}
              onCloseAndOpenClaim={() => {
                setOpenQuickClaimModal(false);
                setOpenClaimModal(true);
              }}
            />
          </ModalContent>
        </Modal>
      </>
    );
  } else if (!claim) {
    return (
      <div className="flex items-center justify-center space-x-1">
        <p className="text-xs text-gray-900">{t('order.claims.claim_auto')}</p>
        <Countdown
          endDate={new Date(message.order.willBeOpenedByBotAt as string)}
          className="inline-flex w-10 items-center justify-center rounded bg-gray-100 px-1 py-0.75 text-xs font-normal text-gray-900"
        />
      </div>
    );
  } else if (isClaimPending(claim)) {
    return (
      <div className="space-y-1 text-center">
        <div className="flex justify-center space-x-1">
          <Icon icon="help" className="text-orange-600" />
          <span className="text-xs font-semibold text-orange-600">
            {t('order.claims.claim_requested')}
          </span>
          {claim.isOpenedByBot ? (
            <span className="inline-flex items-center rounded bg-gray-100 px-1 py-0.75 text-xxs font-normal text-gray-900">
              {t('order.claims.claim_auto_label')}
            </span>
          ) : null}
        </div>
        <div className="text-xs text-gray-500">
          {transformDateSimple(
            new Date(claim.createdAt),
            i18n.language === 'fr' ? 'fr' : 'en',
            'dd/MM/yyyy'
          )}
        </div>
      </div>
    );
  } else if (claim.isAccepted) {
    return (
      <div className="space-y-1 text-center">
        <div className="flex justify-center space-x-1">
          <Check className="h-4 w-4 text-green-600" />
          <span className="text-xs font-semibold text-green-600">
            {t('order.claims.claim_accepted')}
          </span>
          {claim.isOpenedByBot ? (
            <span className="inline-flex items-center rounded bg-gray-100 px-1 py-0.75 text-xxs font-normal text-gray-900">
              Auto
            </span>
          ) : null}
        </div>
        <div className="text-xs text-gray-500">
          {transformDateSimple(
            new Date(claim.updatedAt),
            i18n.language === 'fr' ? 'fr' : 'en',
            'dd/MM/yyyy'
          )}
        </div>
      </div>
    );
  }
  return null;
};
