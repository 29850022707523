import React, { useCallback, useEffect, useState } from 'react';

import { useKeyboardEvent } from '@react-hookz/web';
import useEmblaCarousel from 'embla-carousel-react';

import { NextButton, PrevButton } from './image-gallery-arrows-buttons';
import { Thumb } from './image-gallery-thumbs-button';

import './image-gallery.css';

export type Slide = {
  index: number;
  src: string;
};

type ImageGalleryProps = {
  slides: Slide[] | undefined;
  initialIndex?: number;
  onClose: () => void;
};

const imageByIndex = (slides: Slide[], index: number) =>
  slides[index % slides.length];

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  slides,
  initialIndex = 0,
  onClose,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    loop: true,
    watchDrag: false,
    startIndex: selectedIndex,
  });
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    watchDrag: false,
  });

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return;
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi, emblaThumbsApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  const scrollPrev = useCallback(
    () => emblaMainApi && emblaMainApi.scrollPrev(),
    [emblaMainApi]
  );
  const scrollNext = useCallback(
    () => emblaMainApi && emblaMainApi.scrollNext(),
    [emblaMainApi]
  );

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect);
    emblaMainApi.on('reInit', onSelect);
  }, [emblaMainApi, onSelect]);

  useKeyboardEvent('ArrowLeft', scrollPrev, [emblaMainApi]);
  useKeyboardEvent('ArrowRight', scrollNext, [emblaMainApi]);

  if (!slides || slides.length === 0) return null;

  return (
    <div className="embla">
      <div className="absolute inset-0" onClick={onClose}></div>
      <div className="embla__viewport" ref={emblaMainRef}>
        <div className="embla__container">
          {slides.map((slide) => (
            <div className="embla__slide" key={slide.index}>
              <div className="absolute inset-0" onClick={onClose}></div>
              <div className="embla__slide__number">
                <span>{slide.index + 1}</span>
              </div>
              <img
                className="embla__slide__img"
                src={imageByIndex(slides, slide.index)?.src}
                alt=""
              />
            </div>
          ))}
        </div>
        <PrevButton onClick={scrollPrev} enabled={true} />
        <NextButton onClick={scrollNext} enabled={true} />
      </div>

      <div className="embla-thumbs">
        <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
          <div className="embla-thumbs__container">
            <div className="absolute inset-0" onClick={onClose}></div>
            {slides.map((slide) => (
              <Thumb
                onClick={() => onThumbClick(slide.index)}
                selected={
                  slide.index === imageByIndex(slides, selectedIndex)?.index
                }
                index={slide.index}
                imgSrc={imageByIndex(slides, slide.index)?.src ?? ''}
                key={slide.index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ImageGallery.displayName = 'ImageGallery';
