import { useEffect } from 'react';

import { useLocalStorageValue, useUpdateEffect } from '@react-hookz/web';
import { useAtom } from 'jotai';

import { sortState } from '../../stores/messages';
import { SortType } from '../../types/generated';

export const useSort = (): [SortType, (sort: SortType) => void] => {
  const { value: cachedSort, set: setCachedSort } =
    useLocalStorageValue<string>('sortingOption', {
      defaultValue: 'ASC',
    });
  const [sort, setSort] = useAtom(sortState);

  useEffect(() => {
    let defaultSort: SortType;
    switch (cachedSort) {
      case 'ASC':
        defaultSort = SortType.Asc;
        break;
      case 'DESC':
        defaultSort = SortType.Desc;
        break;
      case 'AI_SCORING':
        defaultSort = SortType.AiScoring;
        break;
      default:
        defaultSort = SortType.Asc;
        break;
    }
    setSort(defaultSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    setCachedSort(sort);
  }, [sort, setCachedSort]);

  return [sort, setSort];
};
