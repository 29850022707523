import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatPrice } from '../../../utils/currency';
import type { StepProps, StepRef } from '../other-actions-modal';
import { OtherActionsShippingRefundStepRefund } from './other-actions-shipping-refund/other-actions-shipping-refund-step-refund';
import { OtherActionsShippingRefundStepSimulation } from './other-actions-shipping-refund/other-actions-shipping-refund-step-simulation';

export const OtherActionsShippingRefund = forwardRef<StepRef, StepProps>(
  ({ order, step, setSubmitTitle, setNumberOfSteps, setShowFooter }) => {
    const { i18n, t } = useTranslation();

    useEffect(() => {
      setNumberOfSteps(4);
    }, []);

    if (step === 1) {
      return (
        <div className="rounded border border-gray-300 p-4">
          <p className="text-sm font-bold">{order?.seller?.name}</p>
          <p className="pt-2 text-xs text-gray-500">{order?.id}</p>
          <p className="pt-6 text-base font-bold text-gray-900">
            {t('other_actions.refund.shipping_cost')}{' '}
            {formatPrice(
              (order?.shipping.feesSold ?? 0) / 100,
              i18n.language === 'fr' ? 'fr' : 'en',
              order?.totalPriceSold.currency ?? 'EUR'
            )}
          </p>
        </div>
      );
    }

    if (step === 2) {
      return (
        <OtherActionsShippingRefundStepSimulation
          order={order}
          setSubmitTitle={setSubmitTitle}
        />
      );
    }

    return (
      <OtherActionsShippingRefundStepRefund
        order={order}
        setShowFooter={setShowFooter}
      />
    );
  }
);

OtherActionsShippingRefund.displayName = 'OtherActionsShippingRefund';
