import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddressFormFields from '@appchoose/address-form-fields';
import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabelCheckbox,
} from '@appchoose/form';

import type { Order } from '../../types/order';
import type { OtherActionsForm } from './other-actions-modal';

type OtherActionsModalShippingAddressFormFieldsProps = {
  order?: Order;
};

export const OtherActionsModalShippingAddressFormFields = ({
  order,
}: OtherActionsModalShippingAddressFormFieldsProps) => {
  const { i18n, t } = useTranslation();

  const form = useFormContext<OtherActionsForm>();

  const brandNames = order?.billingSellerNames ?? [];

  return (
    <div className="space-y-6">
      {brandNames.length > 1 ? (
        <div className="space-y-4 rounded border border-gray-500 p-6">
          <p className="font-semibold text-gray-700">
            {t(
              'other_actions.step_params.fields.all_orders.part_of_grouped_order'
            )}
          </p>
          <FormField
            control={form.control}
            name="allOrders"
            render={({ field }) => (
              <FormItem>
                <div className="flex space-x-3">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value=""
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabelCheckbox>
                    <p>
                      {t('other_actions.step_params.fields.all_orders.label')}
                    </p>
                    <p className="text-gray-500">
                      {t(
                        'other_actions.step_params.fields.all_orders.description',
                        {
                          x: brandNames.length,
                          brandNames: new Intl.ListFormat(
                            i18n.language === 'fr' ? 'fr' : 'en',
                            { style: 'long', type: 'conjunction' }
                          ).format(brandNames),
                        }
                      )}
                    </p>
                  </FormLabelCheckbox>
                </div>
              </FormItem>
            )}
          />
        </div>
      ) : null}
      <AddressFormFields
        googleMapsApiKey={
          import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
        }
        locale={i18n.language}
        formPrefix="shipping"
        showCompany={false}
        showName={true}
        showPhone={true}
        translations={{
          suggestions: t('suggestions'),
          fieldsNameValidationErrorsRequired: t(
            'address.fields.name.validation_errors.required'
          ),
          fieldsNameValidationErrorsMaxLength: t(
            'address.fields.name.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsFirstnameValidationErrorsRequired: t(
            'address.fields.firstname.validation_errors.required'
          ),
          fieldsFirstnameValidationErrorsMaxLength: t(
            'address.fields.firstname.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsLastnameValidationErrorsRequired: t(
            'address.fields.lastname.validation_errors.required'
          ),
          fieldsLastnameValidationErrorsMaxLength: t(
            'address.fields.lastname.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsPhoneValidationErrorsRequired: t(
            'address.fields.phone.validation_errors.required'
          ),
          fieldsPhoneValidationErrorsMaxLength: t(
            'address.fields.phone.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsStreetValidationErrorsRequired: t(
            'address.fields.street.validation_errors.required'
          ),
          fieldsStreetValidationErrorsMaxLength: t(
            'address.fields.street.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsStreet2ValidationErrorsMaxLength: t(
            'address.fields.street2.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsBpValidationErrorsRequired: t(
            'address.fields.bp.validation_errors.required'
          ),
          fieldsBpValidationErrorsMaxLength: t(
            'address.fields.bp.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsCityValidationErrorsRequired: t(
            'address.fields.city.validation_errors.required'
          ),
          fieldsCityValidationErrorsMaxLength: t(
            'address.fields.city.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsProvinceValidationErrorsRequired: t(
            'address.fields.province.validation_errors.required'
          ),
          fieldsProvinceValidationErrorsMaxLength: t(
            'address.fields.province.validation_errors.maxLength',
            {
              maxLength: '200',
            }
          ),
          fieldsCountryValidationErrorsRequired: t(
            'address.fields.country.validation_errors.required'
          ),
          fieldsNameLabel: t('address.fields.name.label_return'),
          fieldsNamePlaceholder: t('address.fields.name.placeholder'),
          fieldsFirstnameLabel: t('address.fields.firstname.label'),
          fieldsFirstnamePlaceholder: t('address.fields.firstname.placeholder'),
          fieldsLastnameLabel: t('address.fields.lastname.label'),
          fieldsLastnamePlaceholder: t('address.fields.lastname.placeholder'),
          fieldsPhoneLabel: t('address.fields.phone.label'),
          fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
          fieldsStreetLabel: t('address.fields.street.label'),
          fieldsStreetPlaceholder: t('address.fields.street.placeholder'),
          fieldsStreet2Label: t('address.fields.street2.label'),
          fieldsStreet2Placeholder: t('address.fields.street2.placeholder'),
          fieldsBpLabel: t('address.fields.bp.label'),
          fieldsBpPlaceholder: '',
          fieldsCityLabel: t('address.fields.city.label'),
          fieldsCityPlaceholder: '',
          fieldsProvinceLabel: t('address.fields.province.label'),
          fieldsProvincePlaceholder: '',
          fieldsProvinceEmptyField: t('address.fields.province.empty_field'),
          fieldsCountryLabel: t('address.fields.country.label'),
          fieldsCountryPlaceholder: t('address.fields.country.placeholder'),
          fieldsCountryPlaceholderSearch: t(
            'address.fields.country.placeholder_search'
          ),
          fieldsCountryEmptyField: t('address.fields.country.empty_field'),
          fieldsCountryNoResults: t('address.fields.country.no_results'),
        }}
      />
    </div>
  );
};
