import type { ChangeEvent } from 'react';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormLabelCheckbox,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input, { InputAdornment } from '@appchoose/input';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';

import { selectedSaleState } from '../../stores/group-message/selectedSale';
import { formatRange } from '../../utils/date';
import { getEstimatedDeliveryDates } from '../../utils/utils';
import { FilesPreview } from '../files-preview/files-preview';
import { TextArea } from '../inputs/text-area';
import type { SaleGroupMessageForm } from './sale-group-message-modal';
import { EstimatedDateReason } from './sale-group-message-modal';
import { SaleGroupMessageSummary } from './sale-group-message-summary';

const FR_PREDEFINED_DELAY_MESSAGE = `Cher Chooser,

Merci pour votre commande XXX 💛

La marque nous informe avoir besoin de quelques jours supplémentaires afin de préparer et expédier votre commande, j’en suis désolée.

La date de livraison est donc mise à jour sur votre application pour que vous puissiez garder de la visibilité sur l’arrivée du colis.

Bien sûr, je reste à votre écoute si besoin,

Belle journée ☀️`;
const US_PREDEFINED_DELAY_MESSAGE = `Dear Chooser,

Thank you for your XXX order 💛

The brand informs us they need a few extra days to prepare and ship your order, I'm sorry about that.

The delivery date is therefore updated on your app so that you can keep visibility on the arrival of the package.

Of course, I remain at your disposal should you need any assistance,

Have a nice day ☀️`;

export const SaleGroupMessageModalStepOptions: React.FC = () => {
  const { i18n, t } = useTranslation();

  const selectedSale = useAtomValue(selectedSaleState);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const form = useFormContext<SaleGroupMessageForm>();

  useEffect(() => {
    if (form.getValues('motiveId') === 'reason_late_delivery') {
      form.setValue(
        'message',
        form.getValues('saleStoreId') === 'us'
          ? US_PREDEFINED_DELAY_MESSAGE
          : FR_PREDEFINED_DELAY_MESSAGE
      );
    } else {
      form.resetField('message');
    }
    return () => {
      form.unregister('message', { keepValue: true, keepIsValid: false });
    };
  }, []);

  const onRemoveFile = (index: number) => {
    const files = form.getValues('images');
    if (files?.[index]) {
      const tmp = [...files];
      tmp.splice(index, 1);
      form.setValue('images', tmp);
    }
  };

  form.watch('estimatedDateReason');
  form.watch('newEstimatedDeliveryStartDate');
  form.watch('isCreditChecked');
  form.watch('images');

  form.register('images');

  const reasonOptions = [
    {
      value: EstimatedDateReason.PostponeDate,
      name: t('message_group.fields.estimated_date_reason.postpone_date'),
    },
    {
      value: EstimatedDateReason.ChangeDate,
      name: t('message_group.fields.estimated_date_reason.change_date'),
    },
    {
      value: EstimatedDateReason.NoChange,
      name: t('message_group.fields.estimated_date_reason.no_change'),
    },
  ];

  return (
    <>
      <h2 className="mb-6 text-2xl font-bold text-gray-900">
        {t('message_group.contact_group_of_customers')}
      </h2>
      <SaleGroupMessageSummary />
      {form.getValues('motiveId') === 'reason_late_delivery' ? (
        <>
          <div className="mb-6">
            <FormField
              control={form.control}
              name="estimatedDateReason"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      className="gap-4"
                    >
                      {reasonOptions.map((option) => (
                        <div
                          className="group flex items-center space-x-3"
                          key={option.value}
                        >
                          <RadioGroupItem
                            id={`estimated-date-option-${option.value}`}
                            key={option.value}
                            value={option.value}
                          />
                          <Label
                            htmlFor={`estimated-date-option-${option.value}`}
                            className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                          >
                            {option.name}
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          {form.getValues('estimatedDateReason') ===
          EstimatedDateReason.PostponeDate ? (
            <div className="mb-4">
              <FormField
                control={form.control}
                name="newEstimatedDeliveryPostponeDays"
                rules={{
                  required: true,
                  min: 1,
                }}
                render={({ field, fieldState: { invalid } }) => (
                  <FormItem>
                    <FormLabel>
                      {t(
                        'message_group.fields.new_estimated_delivery_postpone_days.label'
                      )}
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(event) => {
                          field.onChange(
                            !isNaN(event.target.valueAsNumber)
                              ? event.target.valueAsNumber
                              : null
                          );
                        }}
                      />
                    </FormControl>
                    {!invalid ? (
                      <span className="whitespace-pre-line text-xs">
                        {t(
                          'message_group.fields.new_estimated_delivery_postpone_days.help'
                        )}
                      </span>
                    ) : null}
                    <FormMessage match="required">
                      {t(
                        'message_group.fields.new_estimated_delivery_postpone_days.validation_errors.required'
                      )}
                    </FormMessage>
                    <FormMessage match="min">
                      {t(
                        'message_group.fields.new_estimated_delivery_postpone_days.validation_errors.min',
                        {
                          min: '1',
                        }
                      )}
                    </FormMessage>
                  </FormItem>
                )}
              />
            </div>
          ) : null}
          {form.getValues('estimatedDateReason') ===
          EstimatedDateReason.ChangeDate ? (
            <>
              <div className="mb-4">
                <FormField
                  control={form.control}
                  name="newEstimatedDeliveryStartDate"
                  rules={{
                    required:
                      form.getValues('motiveId') === 'reason_late_delivery' &&
                      form.getValues('estimatedDateReason') ===
                        EstimatedDateReason.ChangeDate,
                    min: format(new Date(), 'yyyy-MM-dd'),
                  }}
                  render={({ field, fieldState: { invalid } }) => (
                    <FormItem>
                      <FormLabel>
                        {t(
                          'message_group.fields.new_estimated_delivery_start_date.label'
                        )}
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="date"
                          onChange={(e) => {
                            field.onChange(e);
                            const { startDate, endDate } =
                              getEstimatedDeliveryDates(
                                e.target.valueAsDate as Date
                              );
                            form.setValue(
                              'computedEstimatedDeliveryStartDate',
                              startDate
                            );
                            form.setValue(
                              'computedEstimatedDeliveryEndDate',
                              endDate
                            );
                          }}
                          min={format(new Date(), 'yyyy-MM-dd')}
                        />
                      </FormControl>
                      {!invalid ? (
                        <span className="whitespace-pre-line text-xs">
                          {t(
                            'message_group.fields.new_estimated_delivery_start_date.help'
                          )}
                        </span>
                      ) : null}
                      <FormMessage match="required">
                        {t(
                          'message_group.fields.new_estimated_delivery_start_date.validation_errors.required'
                        )}
                      </FormMessage>
                      <FormMessage match="min">
                        {t(
                          'message_group.fields.new_estimated_delivery_start_date.validation_errors.min'
                        )}
                      </FormMessage>
                    </FormItem>
                  )}
                />
              </div>
              {form.getValues('computedEstimatedDeliveryStartDate') ? (
                <div className="mb-6 space-y-2">
                  <p className="text-sm">
                    {t('message_group.display_on_the_app')}
                  </p>
                  <p className="font-semibold">
                    {t('message_group.estimated_delivery', {
                      date: formatRange(
                        form.getValues('computedEstimatedDeliveryStartDate'),
                        form.getValues('computedEstimatedDeliveryEndDate'),
                        i18n.language === 'fr' ? 'fr' : 'en'
                      ),
                    })}
                  </p>
                </div>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <div className="mb-4">
        <FormField
          control={form.control}
          name="message"
          rules={{ required: true }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('message_group.fields.message.label')}</FormLabel>
              <FormControl>
                <TextArea
                  {...field}
                  rows={4}
                  placeholder={t('message_group.fields.message.placeholder')}
                />
              </FormControl>
              <FormMessage match="required">
                {t('message_group.fields.message.validation_errors.required')}
              </FormMessage>
            </FormItem>
          )}
        />
      </div>
      <div className="mb-6">
        <FilesPreview
          files={form.getValues('images')}
          canRemove={true}
          onRemoveFile={onRemoveFile}
          className="mb-4"
          sizeClassName="h-12"
        />
        <input
          ref={inputFile}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files)
              form.setValue('images', [
                ...(form.getValues('images') || []),
                ...Array.from(e.target.files),
              ]);
          }}
          type="file"
          multiple
          accept="image/*"
          className="hidden"
        />
        <button
          type="button"
          onClick={() => inputFile.current?.click()}
          className="flex items-center space-x-1 text-xs font-semibold text-green-900"
        >
          <Icon icon="paperClip" />
          <span>{t('message_group.fields.image_upload.attach_image')}</span>
        </button>
      </div>
      <h3 className="mb-4 text-base font-semibold text-gray-700">
        {t('message_group.option')}
      </h3>
      <div className="flex flex-col">
        <FormField
          control={form.control}
          name="isCreditChecked"
          render={({ field }) => (
            <FormItem>
              <div className="flex space-x-3">
                <FormControl>
                  <Checkbox
                    {...field}
                    value=""
                    checked={field.value}
                    onCheckedChange={(value) => {
                      field.onChange(value);
                      if (value === false) {
                        form.resetField('creditAmount');
                      }
                    }}
                  />
                </FormControl>
                <FormLabelCheckbox>
                  {t('message_group.credit_compensation')}
                </FormLabelCheckbox>
              </div>
            </FormItem>
          )}
        />

        {form.getValues('isCreditChecked') ? (
          <div className="ml-8 mt-2" style={{ width: '176px' }}>
            <FormField
              control={form.control}
              name="creditAmount"
              rules={{
                required: true,
                min: 0,
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="sr-only">
                    {t('message_group.fields.amount_credit_compensation.label')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      placeholder="X"
                      endAdornment={
                        <InputAdornment position="end">
                          <span>
                            {selectedSale?.store_id === 'us' ? '$' : '€'}
                          </span>
                        </InputAdornment>
                      }
                      className="spin-button-none"
                    />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'message_group.fields.amount_credit_compensation.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="min">
                    {t(
                      'message_group.fields.amount_credit_compensation.validation_errors.min',
                      { min: 0 }
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
