import { useCallback, useState } from 'react';

import { useIsMounted } from '@react-hookz/web';

export type CopyToClipboardState = {
  value?: string;
  error?: Error;
};

export const useCopyToClipboard = (): [
  CopyToClipboardState,
  (value: string) => void,
] => {
  const isMounted = useIsMounted();
  const [state, setState] = useState<CopyToClipboardState>({
    value: undefined,
    error: undefined,
  });
  const copyToClipboard = useCallback((value: string) => {
    if (!isMounted()) {
      return;
    }
    try {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(value).then(
          () =>
            setState({
              value: value,
              error: undefined,
            }),
          (error) =>
            setState({
              value: value,
              error: error as Error,
            })
        );
      } else {
        setState({
          value: value,
          error: new Error('Clipboard API not available'),
        });
      }
    } catch (error) {
      setState({
        value: value,
        error: error as Error,
      });
    }
  }, []);

  return [state, copyToClipboard];
};
