import { atom } from 'jotai';

import type { Slide } from '../components/image-gallery/image-gallery';

export const imageViewerState = atom<
  | {
      slides: Slide[];
      initialIndex?: number;
    }
  | undefined
>(undefined);
