import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { ModalFooter, ModalHeader, ModalTitle } from '@appchoose/modal';

type CancelReturnConfirmationModalProps = {
  closeModal: (confirm: boolean) => void;
};

export const CancelReturnConfirmationModal: React.FC<
  CancelReturnConfirmationModalProps
> = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col justify-between overflow-hidden">
      <div className="p-10">
        <ModalHeader>
          <ModalTitle>
            {t('order.returns.cancel_return_confirmation_modal.cancel_return')}
          </ModalTitle>
        </ModalHeader>
        <div className="mb-12 text-sm text-gray-700">
          {t(
            'order.returns.cancel_return_confirmation_modal.cancel_return_confirmation'
          )}
        </div>
      </div>
      <ModalFooter>
        <Button
          type="button"
          appearance="outline"
          onClick={() => closeModal(false)}
          tabIndex={-1}
        >
          {t('cancel')}
        </Button>
        <Button type="submit" onClick={() => closeModal(true)}>
          {t('order.returns.cancel_return_confirmation_modal.cancel_return')}
        </Button>
      </ModalFooter>
    </div>
  );
};
