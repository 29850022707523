import type { CashbackUpdate } from '../types/generated';

export const hasPostActionCashbackAmountChanged = (
  cashbackUpdate: CashbackUpdate | null | undefined
) => {
  return (
    cashbackUpdate &&
    cashbackUpdate.currentAmount !== cashbackUpdate.postActionAmount
  );
};
