import type { CSSProperties } from 'react';

import { useAtomValue } from 'jotai';

import { operatorsState } from '../../stores/operators';
import { capitalize } from '../../utils/string';

import './operator-avatar.scss';

export type OperatorAvatarProps = {
  operatorId: string;
  size: 'small' | 'medium';
  showBorder: boolean;
  style?: CSSProperties;
};

/**
 * Avatar component.
 */
export const OperatorAvatar: React.FC<OperatorAvatarProps> = ({
  operatorId,
  size,
  showBorder,
  style,
}: OperatorAvatarProps) => {
  const operators = useAtomValue(operatorsState);

  const foundOperator = operators.find((o) => o.operatorId === operatorId);

  const operatorImage = foundOperator?.image ?? '';
  const operatorName = foundOperator?.displayName ?? '';

  const newStyle: CSSProperties = {
    border: showBorder ? '1px solid #F2F4F5' : undefined,
    width: size === 'medium' ? '24px' : '16px',
    height: size === 'medium' ? '24px' : '16px',
    ...style,
  };

  const fistNameInitial = capitalize(operatorName.charAt(0));
  const lastNameInitial = capitalize(operatorName.split(' ')?.[1]?.charAt(0));

  return operatorImage ? (
    <img
      className="operator-avatar operator-avatar-image"
      src={operatorImage}
      alt={operatorName}
      title={operatorName}
      style={newStyle}
    ></img>
  ) : (
    <span
      className={`operator-avatar operator-avatar-text ${size}`}
      style={newStyle}
    >
      <span>{fistNameInitial + lastNameInitial}</span>
    </span>
  );
};

OperatorAvatar.displayName = 'OperatorAvatar';
