import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@appchoose/toast';

import { useCancelOrderItemsMutation } from '../../../types/generated';
import type {
  OtherActionsForm,
  StepProps,
  StepRef,
} from '../other-actions-modal';
import { OtherActionsModalCancelOrderFormFields } from '../other-actions-modal-cancel-order-form-fields';
import { OtherActionsModalConfirmationDelete } from '../other-actions-modal-confirmation-delete';
import { OtherActionsModalDeleteProductsFormFields } from '../other-actions-modal-delete-products-form-fields';

export const OtherActionsDeleteArticles = forwardRef<StepRef, StepProps>(
  ({ step, order, setNumberOfSteps }, ref) => {
    const { t } = useTranslation();

    useEffect(() => {
      setNumberOfSteps(4);
    }, []);

    const [cancelOrderItemsMutation, { error: cancelOrderItemsError }] =
      useCancelOrderItemsMutation();

    useEffect(() => {
      if (cancelOrderItemsError) {
        toast.error(t('other_actions.errors.remove_order_products_error'));
      }
    }, [cancelOrderItemsError]);

    useImperativeHandle(ref, () => ({
      onNextStep: async (data: OtherActionsForm) => {
        await cancelOrderItemsMutation({
          variables: {
            orderId: order?.id ?? '',
            itemIds: data.products
              .filter((product) => product.selected)
              .map((product) => product.product.id),
            input: {
              putBackOrderItemsInStock: data.putBackInStock,
              notifyCustomerAboutItemsOutOfStock: true,
              orderCancelledCreditCompensationInCents: 500,
            },
          },
        });

        toast.success(t('other_actions.confirmation_message.delete_articles'));
      },
    }));

    return (
      <>
        {step === 1 && <OtherActionsModalDeleteProductsFormFields />}
        {step === 2 && (
          <OtherActionsModalCancelOrderFormFields
            order={order}
            isPartialCancel={true}
          />
        )}
        {step === 3 && <OtherActionsModalConfirmationDelete order={order} />}
      </>
    );
  }
);

OtherActionsDeleteArticles.displayName = 'OtherActionsDeleteArticles';
