import { useTranslation } from 'react-i18next';

import { StoreCurrency, StoreRegion } from '../../types/generated';
import type { IUser } from '../../types/user';
import { transformPrice } from '../../utils/currency';

type CreditDisplayProps = {
  user: IUser;
};

export const CreditDisplay: React.FC<CreditDisplayProps> = ({
  user,
}: CreditDisplayProps) => {
  const { i18n } = useTranslation();

  const euroCredit = user.credits.find(
    (credit) => credit.currency === StoreCurrency.Eur
  );
  const euroValue = transformPrice(
    euroCredit?.value ?? 0,
    i18n.language === 'fr' ? 'fr' : 'en',
    'EUR',
    0
  );
  const dollarCredit = user.credits.find(
    (credit) => credit.currency === StoreCurrency.Usd
  );
  const dollarValue = transformPrice(
    dollarCredit?.value ?? 0,
    i18n.language === 'fr' ? 'fr' : 'en',
    'USD',
    0
  );
  const isUS = user.currentStore === StoreRegion.Us;
  const showSecondaryCredit =
    (isUS ? (euroCredit?.value ?? 0) : (dollarCredit?.value ?? 0)) > 0;

  return (
    <>
      <span className="pr-2">{isUS ? dollarValue : euroValue}</span>
      {showSecondaryCredit ? (
        <>
          <hr className="h-3 w-px bg-gray-300" />
          <span className="pl-2">{isUS ? euroValue : dollarValue}</span>
        </>
      ) : null}
    </>
  );
};
