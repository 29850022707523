import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '@appchoose/checkbox';
import { FormControl, FormField, FormItem } from '@appchoose/form';

import { ClaimReason } from '../../types/generated';
import {
  ProductView,
  ProductViewContent,
  ProductViewProductDetails,
} from '../product-view/product-view';
import type { OpenClaimForm } from './open-claim';

type OpenClaimProductsFormFieldsProps = {
  needValidation?: boolean;
};

export const OpenClaimProductsFormFields = ({
  needValidation = true,
}: OpenClaimProductsFormFieldsProps) => {
  const { t } = useTranslation();

  const form = useFormContext<OpenClaimForm>();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const ProductsErrorMessage =
    form.formState.errors.products
      ?.filter?.((s) => s !== undefined)
      .map((s) => s.selected?.type)
      .find((t) => t === 'atLeastOne') &&
    t('claim.open_claim.step_products.products.validation_errors.at_least_one');

  return (
    <>
      <h4 className="mb-4 font-semibold text-gray-700">
        {form.getValues('reason') === ClaimReason.DeliveredButWrong
          ? t('claim.open_claim.step_products.select_wrong_products')
          : t('claim.open_claim.step_products.select_missing_products')}
      </h4>
      <div className="flex flex-wrap gap-2">
        {fields.map((field, index) => (
          <label key={field.id} className="w-full">
            <ProductView isSelected={field.selected} isDisabled={false}>
              <ProductViewContent>
                <ProductViewProductDetails item={field.product} />
                <div className="flex h-14 items-center">
                  <FormField
                    control={form.control}
                    name={`products.${index}.selected`}
                    rules={{
                      validate: needValidation
                        ? {
                            atLeastOne: () =>
                              form
                                .getValues('products')
                                .filter((s) => s.selected).length > 0,
                          }
                        : {},
                    }}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Checkbox
                            {...field}
                            value=""
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </ProductViewContent>
            </ProductView>
          </label>
        ))}
      </div>
      <div className="mt-2 text-xs text-red-600">{ProductsErrorMessage}</div>
    </>
  );
};
