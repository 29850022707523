import { useTranslation } from 'react-i18next';

import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';

import type { ConversationMessageGroupItemConcern } from '../../types/generated';
import { ProductModal } from '../product-modal/product-modal';

export type ProductDetailProps = {
  item: ConversationMessageGroupItemConcern & {
    sku?: string;
    gtin?: string;
  };
};

export const ProductDetail: React.FC<ProductDetailProps> = ({
  item,
}: ProductDetailProps) => {
  const { t } = useTranslation();

  return (
    <Modal>
      <ModalTrigger className="product relative flex cursor-pointer flex-row items-start space-x-4 text-left">
        {item.image && (
          <img
            src={item.image}
            className="size-15 rounded border-0.5 border-gray-300 object-cover"
          />
        )}
        <div>
          <p className="m-0 text-sm leading-5.5">{item.description}</p>

          {item.options ? (
            <p className="m-0 text-xs text-gray-700">
              <span>{item.options}</span>
            </p>
          ) : null}

          {item.sku ? (
            <p className="text-xs text-gray-700">
              {t('order.sku')} <span>{item.sku}</span>
            </p>
          ) : null}
          {item.gtin ? (
            <p className="text-xs text-gray-700">
              {t('order.gtin')} <span>{item.gtin}</span>
            </p>
          ) : null}
        </div>
      </ModalTrigger>
      <ModalContent scrollable>
        <ProductModal productId={item.productId} />
      </ModalContent>
    </Modal>
  );
};

ProductDetail.displayName = 'ProductDetail';
