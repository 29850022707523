import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Badge from '@appchoose/badge';
import Checkbox from '@appchoose/checkbox';
import cn from '@appchoose/cn';
import { useUpdateEffect } from '@react-hookz/web';

type RefundProductProps = {
  checkboxIdentifier: string;
  defaultChecked?: boolean;
  image: string;
  priceSold: string;
  refundAmount?: number;
  size?: string | null;
  title: string;
  handleSelectProduct?: () => void;
  handleUnselectProduct?: () => void;
};

export const RefundProduct: React.FC<RefundProductProps> = ({
  defaultChecked,
  handleSelectProduct,
  handleUnselectProduct,
  image,
  priceSold,
  refundAmount,
  size,
  title,
}: RefundProductProps) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = () => {
    setChecked(!checked);
  };

  useUpdateEffect(() => {
    if (checked) {
      handleSelectProduct?.();
    }
    handleUnselectProduct?.();
  }, [checked]);

  return (
    <label
      className={cn(`block cursor-pointer space-y-2`, {
        'cursor-not-allowed': refundAmount === 100,
      })}
    >
      {refundAmount ? (
        <Badge
          appearance={refundAmount === 100 ? 'success' : 'default'}
          size="medium"
        >
          {t('other_actions.refund.percentage_already_refunded', {
            amount: refundAmount,
          })}
        </Badge>
      ) : null}
      <div className="flex flex-row items-center">
        <img
          src={image}
          alt={title}
          className="size-14 rounded border border-gray-300"
        />
        <div className="mx-3 grow space-y-1">
          <p className="text-sm">{title}</p>
          {size ? (
            <p className="text-xs text-gray-700">
              {t('order.product.size')} {size}
            </p>
          ) : null}
          <p className="text-xs text-gray-700">
            {t('other_actions.refund.purchase_price', {
              purchase_price: priceSold,
            })}
          </p>
        </div>
        <Checkbox
          disabled={refundAmount === 100}
          checked={checked}
          onCheckedChange={handleChange}
        />
      </div>
    </label>
  );
};
