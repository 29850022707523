import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input from '@appchoose/input';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { toast } from '@appchoose/toast';
import { useAtom, useSetAtom } from 'jotai';
import { matchSorter } from 'match-sorter';
import { v4 as uuidv4 } from 'uuid';

import { categoriesState, templatesState } from '../../stores/templates';
import type { CategoryData, TemplateData } from '../../types/generated';
import {
  useTemplatesLazyQuery,
  useUpdateTemplateMessageMutation,
} from '../../types/generated';
import { SimpleTextArea } from '../simple-textarea/simple-textarea';

import '@appchoose/searchable-select/dist/style.css';

type NewTemplateProps = {
  setIsOpen: (isOpen: boolean) => void;
  existingTemplate?: TemplateData;
};

type NewTemplateForm = {
  key: string;
  title: string;
  category: string;
  message: string;
};

export const NewTemplate = ({
  setIsOpen,
  existingTemplate,
}: NewTemplateProps) => {
  const { t } = useTranslation();

  const form = useForm<NewTemplateForm>({
    mode: 'onTouched',
    defaultValues: {
      key: existingTemplate?.key ?? uuidv4().replace(/-/g, '').slice(0, 5),
      title: existingTemplate?.title ?? '',
      category: existingTemplate?.category?.toString() ?? undefined,
      message: existingTemplate?.message ?? '',
    },
  });

  // Force <SimpleTextArea /> rerender to avoid height issues on mount
  const [key, setKey] = useState('new-template-message');
  useEffect(() => {
    setKey('new-template-message-2');
  }, []);

  const [categories, setCategories] = useAtom(categoriesState);
  const setTemplates = useSetAtom(templatesState);

  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();

  const categoryOptions = (categories ?? []).map((category) => ({
    value: category.id?.toString() ?? '',
    label: category.name ?? '',
  }));

  const categoryMatches = useMemo(
    () => matchSorter(categoryOptions, searchQuery, { keys: ['label'] }),
    [categoryOptions, searchQuery]
  );

  const [templates, { data: templatesData, error: templatesError }] =
    useTemplatesLazyQuery();

  const [updateTemplateMessage, { error: updateTemplateMessageError }] =
    useUpdateTemplateMessageMutation();

  useEffect(() => {
    if (templatesData) {
      setTemplates(
        templatesData?.templates?.templates?.filter(
          (template): template is TemplateData => !!template
        )
      );
      setCategories(
        templatesData?.templates?.categories?.filter(
          (category): category is CategoryData => !!category
        )
      );
      setIsOpen(false);
    }
  }, [templatesData]);

  useEffect(() => {
    if (templatesError) {
      toast.error(
        'Une erreur est survenue en récupérant la liste des templates'
      );
    }
  }, [templatesError]);

  useEffect(() => {
    if (updateTemplateMessageError) {
      toast.error('Une erreur est survenue en créant un nouveau template');
    }
  }, [updateTemplateMessageError]);

  const onSubmit = async (data: NewTemplateForm) => {
    try {
      await updateTemplateMessage({
        variables: {
          data: {
            category: parseInt(data.category, 10),
            key: data.key,
            message: data.message,
            title: data.title,
          },
        },
      });

      templates();
    } catch {
      // Something went wrong
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col space-y-6 overflow-y-auto p-6 md:p-10">
          <h3
            id="modal-new-template-title"
            className="text-2xl font-bold text-gray-900"
          >
            {!existingTemplate ? (
              <span>{t('templates.template.new_template')}</span>
            ) : (
              <span>{t('templates.template.edit_template')}</span>
            )}
          </h3>
          <div className="flex space-x-6">
            <FormField
              control={form.control}
              name="title"
              rules={{
                required: true,
                maxLength: 200,
              }}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    {t('templates.template.fields.title.label')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t(
                        'templates.template.fields.title.placeholder'
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'templates.template.fields.title.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="maxLength">
                    {t(
                      'templates.template.fields.title.validation_errors.maxLength',
                      {
                        maxLength: '200',
                      }
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="category"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>
                    {t('templates.template.fields.category.label')}
                  </FormLabel>
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <button
                          type="button"
                          role="combobox"
                          aria-expanded={open}
                          className='form-input flex w-full items-center justify-between rounded border-gray-500 text-sm leading-5.5 text-gray-900 transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-1 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                        >
                          <div className="flex items-center truncate">
                            {field.value
                              ? categoryOptions.find(
                                  (category) => category.value === field.value
                                )?.label
                              : t(
                                  'templates.template.fields.category.empty_field'
                                )}
                          </div>
                          <Icon
                            icon={open ? 'close' : 'arrowDown'}
                            className="ml-2 size-4 shrink-0 opacity-50"
                          />
                        </button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent
                      className="p-0"
                      align="end"
                      style={{
                        width: 'var(--radix-popover-trigger-width)',
                      }}
                    >
                      <Command shouldFilter={false}>
                        <CommandInput
                          placeholder={t(
                            'templates.template.fields.category.placeholder'
                          )}
                          onValueChange={(value) => {
                            setSearchQuery(value);

                            //#region scroll list to top when typing
                            // https://github.com/pacocoursey/cmdk/issues/234
                            // https://github.com/pacocoursey/cmdk/issues/233

                            // clear pending scroll
                            if (scrollId.current)
                              cancelAnimationFrame(scrollId.current);

                            // the setTimeout is used to create a new task
                            // this is to make sure that we don't scroll until the user is done typing
                            // you can tweak the timeout duration ofc
                            scrollId.current = requestAnimationFrame(() => {
                              // inside your list select the first group and scroll to the top
                              listRef.current?.scrollTo({ top: 0 });
                            });

                            //#endregion
                          }}
                          value={searchQuery}
                        />
                        <CommandList ref={listRef}>
                          <CommandEmpty>
                            {t('templates.template.fields.category.no_result')}
                          </CommandEmpty>

                          <CommandGroup>
                            {categoryMatches.map((category) => (
                              <CommandItem
                                key={category.value}
                                value={category.value}
                                keywords={[category.label]}
                                onSelect={(currentValue) => {
                                  field.onChange(
                                    currentValue === field.value
                                      ? ''
                                      : currentValue
                                  );
                                  setOpen(false);
                                }}
                                className={cn(
                                  'flex items-center justify-between',
                                  {
                                    'font-semibold text-green-900':
                                      field.value === category.value,
                                  }
                                )}
                              >
                                <div className="flex items-center truncate">
                                  {category.label}
                                </div>
                                <Icon
                                  icon="check"
                                  className={cn(
                                    'ml-2 size-4 shrink-0',
                                    field.value === category.value
                                      ? 'opacity-100'
                                      : 'opacity-0'
                                  )}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage match="required">
                    {t(
                      'templates.template.fields.category.validation_errors.required'
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="message"
              rules={{
                required: true,
                maxLength: 10000,
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('templates.template.fields.message.label')}
                  </FormLabel>
                  <FormControl>
                    <SimpleTextArea
                      key={key}
                      placeholder={t(
                        'templates.template.fields.message.placeholder'
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'templates.template.fields.message.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="maxLength">
                    {t(
                      'templates.template.fields.message.validation_errors.maxLength',
                      {
                        maxLength: '10000',
                      }
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />
          </div>
        </div>
        <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
          <Button appearance="primary" type="submit">
            {!existingTemplate ? (
              <span>{t('templates.template.create')}</span>
            ) : (
              <span>{t('templates.template.update')}</span>
            )}
          </Button>
        </footer>
      </form>
    </Form>
  );
};
