import { useEffect, useState } from 'react';
import type { FileWithPath } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { deliveryServices } from '../../types/services';
import { FileUpload } from '../file-upload/file-upload';
import { DeliveryServiceFormFields } from '../other-actions-modal/delivery-service-form-fields';
import { TrackingNumberFormFields } from '../other-actions-modal/tracking-number-form-fields';

type CreateReturnSlipStepTrackingProps = {
  orderId: string;
};

export type CreateReturnSlipTrackingForm = {
  fileUrls: string[];
};

export const CreateReturnSlipStepTracking: React.FC<
  CreateReturnSlipStepTrackingProps
> = ({ orderId }: CreateReturnSlipStepTrackingProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileWithPath[]>([]);

  const {
    formState: { errors },
    register,
    setValue,
    unregister,
  } = useFormContext<CreateReturnSlipTrackingForm>();

  useEffect(() => {
    return () => {
      unregister('fileUrls', { keepValue: false, keepIsValid: false });
    };
  }, []);

  register('fileUrls', {
    required: true,
  });

  const FileUrlsErrorMessage =
    errors.fileUrls?.type === 'required' &&
    t('return.return.fields.file_urls.validation_errors.required');

  return (
    <div>
      <p className="mb-4 font-semibold text-gray-700">
        {t('return.return.add_tracking_info')}
      </p>

      <div className="space-y-6">
        <div className="flex space-x-6">
          <TrackingNumberFormFields />
          <DeliveryServiceFormFields
            deliveryServices={deliveryServices.filter((deliveryService) =>
              ['colissimo', 'usps', 'ups'].includes(deliveryService.type)
            )}
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="file-upload"
            className="text-xs font-semibold uppercase tracking-wider text-gray-700"
          >
            {t('return.return.fields.return_slip.label')}
          </label>
          <FileUpload
            orderId={orderId}
            file={files[0]}
            onFileChange={(file) => setFiles(file)}
            onFileUploaded={(fileUploadResult) =>
              setValue('fileUrls', [fileUploadResult.url])
            }
            onFileCleared={() => setValue('fileUrls', [])}
          />
          <div className="mt-2 text-xs text-red-600">
            {FileUrlsErrorMessage}
          </div>
        </div>
      </div>
    </div>
  );
};
