import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client/react';
import { toast } from '@appchoose/toast';

import { useSplitOrderMutation } from '../../../types/generated';
import type {
  OtherActionsForm,
  StepProps,
  StepRef,
} from '../other-actions-modal';
import { OtherActionsModalSplitProductsFormFields } from '../other-actions-modal-split-products-form-fields';

export const OtherActionsSplitArticles = forwardRef<StepRef, StepProps>(
  ({ step, order }, ref) => {
    const client = useApolloClient();
    const { t } = useTranslation();

    const [splitOrderMutation, { error: splitOrderError }] =
      useSplitOrderMutation();

    useEffect(() => {
      if (splitOrderError) {
        toast.error(t('other_actions.errors.split_order_products_error'));
      }
    }, [splitOrderError]);

    useImperativeHandle(ref, () => ({
      onNextStep: async (data: OtherActionsForm) => {
        await splitOrderMutation({
          variables: {
            orderId: order?.id ?? '',
            newOrdersItemIds: [
              data.products
                .filter((product) => !product.selected)
                .map((product) => product.product.id),
              data.products
                .filter((product) => product.selected)
                .map((product) => product.product.id),
            ],
          },
        });

        client.refetchQueries({
          include: ['CustomerOrders'],
        });

        toast.success(t('other_actions.confirmation_message.split_articles'));
      },
    }));

    return <>{step === 1 && <OtherActionsModalSplitProductsFormFields />}</>;
  }
);

OtherActionsSplitArticles.displayName = 'OtherActionsSplitArticles';
