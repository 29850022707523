import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControl, FormField, FormItem, FormLabel } from '@appchoose/form';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import { useAtomValue } from 'jotai';

import { motivesState } from '../../stores/group-message/motives';
import type { SaleGroupMessageForm } from './sale-group-message-modal';

export const SaleGroupMessageModalStepMotive: React.FC = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<SaleGroupMessageForm>();

  const motives = useAtomValue(motivesState);

  return (
    <>
      <h2
        className="mb-6 text-2xl font-bold text-gray-900"
        id="modal-sale-group-message-title"
      >
        {t('message_group.contact_group_of_customers')}
      </h2>
      <h3 className="mb-4 text-base font-semibold text-gray-700">
        {t('message_group.select_message_reason')}
      </h3>
      <FormField
        control={control}
        name="motiveId"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormItem>
            <FormLabel className="sr-only" hidden>
              {t('message_group.reason_list')}
            </FormLabel>
            <FormControl>
              <RadioGroup
                value={value}
                onValueChange={(value) => onChange(value)}
                className="gap-4"
              >
                {motives.map((option) => (
                  <div
                    className="group flex items-center space-x-3"
                    key={option.id}
                  >
                    <RadioGroupItem
                      id={`currency-option-${option.id}`}
                      key={option.id}
                      value={option.id}
                    />
                    <Label
                      htmlFor={`currency-option-${option.id}`}
                      className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                    >
                      {option.reason}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </>
  );
};
