import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { FormAddress } from '@appchoose/address';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { ModalFooter, ModalHeader, ModalTitle } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import { useAtomValue } from 'jotai';

import { ordersState } from '../../stores/orders';
import { selectedPhotosState } from '../../stores/selected';
import type {
  RefundOrderCoverInput,
  RefundOrderReasonInput,
} from '../../types/generated';
import { ClaimReason, useOpenClaimMutation } from '../../types/generated';
import type { Item, Order } from '../../types/order';
import { getClaimReason } from '../../utils/utils';
import { OpenClaimMessageFormFields } from './open-claim-message-form-fields';
import { OpenClaimPhotosFormFields } from './open-claim-photos-form-fields';
import { OpenClaimProductsFormFields } from './open-claim-products-form-fields';
import { OpenClaimReasonFormFields } from './open-claim-reason-form-fields';

type OpenClaimProps = {
  orderId: string;
  setIsOpen: (isOpen: boolean) => void;
};

export type ClaimOption = {
  value: ClaimReason;
  text: string;
  description?: string;
  disabled: boolean;
};

export type RefundForm = {
  initiator: RefundOrderCoverInput;
  percentage: number;
  refundReason: RefundOrderReasonInput;
  selected: {
    product: Item;
  }[];
};

export type OpenClaimForm = {
  reason: ClaimReason;
  otherReason: string;
  hasAddressChanged: boolean;
  message?: string;
  photos: {
    url: string;
    selected: boolean;
  }[];
  products: {
    product: Item;
    selected: boolean;
  }[];
} & FormAddress;

export const OpenClaim: React.FC<OpenClaimProps> = ({
  orderId,
  setIsOpen,
}: OpenClaimProps) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [stepOrder, setStepOrder] = useState([0]);

  const orders = useAtomValue(ordersState);
  const [order] = useState<Order | undefined>(
    orders.find((o) => o.id === orderId)
  );
  const selectedPhotos = useAtomValue(selectedPhotosState);

  const claimOptions: ClaimOption[] = [
    {
      value: ClaimReason.NotDeliveredAndTrackingDelivered,
      text: getClaimReason(ClaimReason.NotDeliveredAndTrackingDelivered, t),
      disabled: order?.isFullDigital !== false,
    },
    {
      value: ClaimReason.DeliveredButDamaged,
      text: getClaimReason(ClaimReason.DeliveredButDamaged, t),
      description:
        selectedPhotos === null || selectedPhotos.length === 0
          ? t('claim.open_claim.selectable_only_if_photo_available')
          : undefined,
      disabled:
        selectedPhotos === null ||
        selectedPhotos.length === 0 ||
        order?.isFullDigital !== false,
    },
    {
      value: ClaimReason.DeliveredButIncomplete,
      text: getClaimReason(ClaimReason.DeliveredButIncomplete, t),
      disabled: order?.isFullDigital !== false,
    },
    {
      value: ClaimReason.DeliveredButWrong,
      text: getClaimReason(ClaimReason.DeliveredButWrong, t),
      description:
        selectedPhotos === null || selectedPhotos.length === 0
          ? t('claim.open_claim.selectable_only_if_photo_available')
          : undefined,
      disabled:
        selectedPhotos === null ||
        selectedPhotos.length === 0 ||
        order?.isFullDigital !== false,
    },
    {
      value: ClaimReason.NotDeliveredAndReturnedToSender,
      text: getClaimReason(ClaimReason.NotDeliveredAndReturnedToSender, t),
      disabled: order?.isFullDigital !== false,
    },
    {
      value: ClaimReason.NotDeliveredAndTrackingBlocked,
      text: getClaimReason(ClaimReason.NotDeliveredAndTrackingBlocked, t),
      disabled: order?.isFullDigital !== false,
    },
    {
      value: ClaimReason.DigitalCouponCodeNotWorking,
      text: getClaimReason(ClaimReason.DigitalCouponCodeNotWorking, t),
      description:
        (order?.items.filter((item) => item.digitalCouponCode).length ?? 0) > 0
          ? t('claim.open_claim.selectable_only_if_coupon_exists')
          : undefined,
      disabled: !order?.isFullDigital,
    },
    {
      value: ClaimReason.Other,
      text: getClaimReason(ClaimReason.Other, t),
      disabled: false,
    },
  ];

  const form = useForm<OpenClaimForm>({
    mode: 'onTouched',
    defaultValues: {
      hasAddressChanged: false,
      firstname: order?.recipient.firstName,
      lastname: order?.recipient.lastName,
      phone: order?.recipient.phoneNumber,
      street: order?.recipient.address.street,
      street2: order?.recipient.address.streetAdditional ?? undefined,
      city: order?.recipient.address.city,
      bp: order?.recipient.address.zipCode,
      province: order?.recipient.address.county ?? undefined,
      countryCode: order?.recipient.address.countryCode,
      country: order?.recipient.address.country,
      photos:
        selectedPhotos?.map((photo) => ({ url: photo, selected: false })) ?? [],
      products: (order?.items ?? []).map((product) => ({
        product: product,
        selected: false,
      })),
      reason: order?.isFullDigital
        ? ClaimReason.DigitalCouponCodeNotWorking
        : undefined,
    },
  });

  const [
    openClaimMutation,
    { error: openClaimError, loading: openClaimeLoading },
  ] = useOpenClaimMutation();

  useEffect(() => {
    if (openClaimError) {
      toast.error(t('claim.open_claim.error'));
    }
  }, [openClaimError]);

  const goToPreviousStep = () => {
    const previousStep =
      stepOrder[stepOrder.findIndex((st) => st === step) - 1];
    if (previousStep !== undefined) {
      setStepOrder(stepOrder.slice(0, stepOrder.length - 1));
      setStep(previousStep);
    }
  };

  const onSubmit = async (data: OpenClaimForm) => {
    switch (step) {
      case 0:
        if (
          form.getValues('reason') ===
          ClaimReason.NotDeliveredAndTrackingDelivered
        ) {
          setStepOrder([...stepOrder, 1]);
          setStep(1);
        } else if (
          form.getValues('reason') === ClaimReason.DeliveredButDamaged ||
          form.getValues('reason') === ClaimReason.DeliveredButIncomplete ||
          form.getValues('reason') === ClaimReason.DeliveredButWrong ||
          form.getValues('reason') === ClaimReason.Other
        ) {
          if (selectedPhotos !== null && selectedPhotos.length > 0) {
            setStepOrder([...stepOrder, 1]);
            setStep(1);
          } else {
            setStepOrder([...stepOrder, 2]);
            setStep(2);
          }
        } else {
          if (
            form.getValues('reason') === ClaimReason.DigitalCouponCodeNotWorking
          ) {
            setStepOrder([...stepOrder, 2]);
            setStep(2);
          } else {
            setStepOrder([...stepOrder, 3]);
            setStep(3);
          }
        }
        break;
      case 1:
        if (
          form.getValues('reason') ===
          ClaimReason.NotDeliveredAndTrackingDelivered
        ) {
          setStepOrder([...stepOrder, 3]);
          setStep(3);
        } else {
          setStepOrder([...stepOrder, 2]);
          setStep(2);
        }
        break;
      case 2:
        setStepOrder([...stepOrder, 3]);
        setStep(3);
        break;
      case 3:
        try {
          await openClaimMutation({
            variables: {
              orderId: order?.id as string,
              input: {
                reason: data.reason,
                messageFromSupport: data.message,
                proofUrls: data.photos
                  .filter((photo) => photo.selected)
                  .map((photo) => photo.url),
                items: data.products
                  .filter((product) => product.selected)
                  .map((product) => ({
                    productId: product.product.productId,
                    productVariantId: product.product.productVariantId,
                    digitalCouponCode: product.product.digitalCouponCode,
                  })),
                reshipShippingAddress: data.hasAddressChanged
                  ? {
                      firstname: data.firstname ?? '',
                      lastname: data.lastname ?? '',
                      street: data.street,
                      streetAdditional: data.street2,
                      city: data.city,
                      zipCode: data.bp,
                      county: data.province,
                      //country: data.country,
                      countryCode: data.countryCode,
                      phoneNumber: data.phone ?? '',
                    }
                  : undefined,
              },
            },
          });

          toast.success(
            t('claim.open_claim.success', {
              brand_name: order?.seller?.name ?? '',
            })
          );

          setIsOpen(false);
        } catch {
          toast.error(t('claim.open_claim.error'));
        }
        break;
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          <ModalHeader>
            <ModalTitle>
              {t('claim.open_claim.title', {
                brand_name: order?.seller?.name ?? '',
              })}
            </ModalTitle>
          </ModalHeader>
          {step === 0 && <OpenClaimReasonFormFields options={claimOptions} />}
          {step === 1 && <OpenClaimPhotosFormFields />}
          {step === 2 && (
            <OpenClaimProductsFormFields
              needValidation={
                form.getValues('reason') === ClaimReason.Other ? false : true
              }
            />
          )}
          {step === 3 && (
            <OpenClaimMessageFormFields
              order={order}
              isPartialCancel={
                form.getValues('reason') === ClaimReason.DeliveredButIncomplete
              }
            />
          )}
        </div>

        <ModalFooter>
          {step > 0 && (
            <button
              type="button"
              onClick={goToPreviousStep}
              className="rounded px-2 py-1 text-sm font-bold text-green-900 outline-none focus-visible:ring-2 focus-visible:ring-gray-100"
            >
              {t('claim.open_claim.previous')}
            </button>
          )}{' '}
          <Button
            appearance="primary"
            type="submit"
            disabled={openClaimeLoading}
          >
            {step === 3 ? (
              <span>{t('claim.open_claim.confirm')}</span>
            ) : (
              <span>{t('claim.open_claim.next')}</span>
            )}
          </Button>
        </ModalFooter>
      </form>
    </Form>
  );
};
