import type { ChangeEvent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMiniSearch } from 'react-minisearch';
import { NavLink } from 'react-router-dom';

import Icon from '@appchoose/icon';
import Input, { InputAdornment } from '@appchoose/input';
import { useAtomValue } from 'jotai';

import { templatesMapState } from '../../stores/templates';
import type { TemplateData } from '../../types/generated';

type TemplatePopoverContentProps = {
  changeText: (text: TemplateData) => void;
};

export const TemplatePopoverContent = ({
  changeText,
}: TemplatePopoverContentProps) => {
  const { t } = useTranslation();
  const templatesMap = useAtomValue(templatesMapState);
  const [searchTerm, setSearchTerm] = React.useState('');

  const { search, searchResults } = useMiniSearch(
    templatesMap.flatMap((option) =>
      option.data.map((template) => ({
        id: template.key,
        ...template,
      }))
    ),
    {
      fields: ['title', 'message'],
      storeFields: ['title', 'message'],
      searchOptions: { boost: { title: 2 }, prefix: true },
    }
  );

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    search(event.target.value);
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="search-templates" className="sr-only">
          {t('templates.search_template')}
        </label>

        <Input
          id="search-templates"
          type="search"
          placeholder={t('templates.search_template')}
          startAdornment={
            <InputAdornment position="start">
              <Icon icon="search" />
            </InputAdornment>
          }
          defaultValue={searchTerm}
          onChange={onSearchChange}
          autoFocus
        />
      </div>
      {!searchTerm &&
        templatesMap.map((option) => (
          <div className="flex flex-col space-y-2" key={option.key}>
            <div className="flex text-xs uppercase text-gray-500">
              <Icon icon="folder" className="mr-1" />
              <h3 className="text-xs font-semibold tracking-wider">
                {option.name}
              </h3>
            </div>
            {option.data.map((c) => (
              <a
                onClick={() => changeText(c)}
                className="flex cursor-pointer flex-col"
                key={c.key}
              >
                <h5 className="text-sm font-bold text-gray-900">{c.title}</h5>
                <p className="line-clamp-2 text-xs text-gray-700">
                  {c.message}
                </p>
              </a>
            ))}
          </div>
        ))}
      {searchTerm &&
        searchResults?.map((searchResult, i) => (
          <div className="flex flex-col space-y-2" key={i}>
            <a
              onClick={() => {
                changeText(searchResult);
              }}
              className="flex cursor-pointer flex-col"
              key={searchResult.key}
            >
              <h5 className="text-sm font-bold text-gray-900">
                {searchResult.title}{' '}
                <span className="text-gray-500">
                  ({searchResult.category_name})
                </span>
              </h5>
              <p className="line-clamp-2 text-xs text-gray-700">
                {searchResult.message}
              </p>
            </a>
          </div>
        ))}
      <NavLink to="/templates" className="text-sm font-semibold text-green-900">
        Gérer les templates
      </NavLink>
    </div>
  );
};
