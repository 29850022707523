import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { OpenClaimForm } from './open-claim';
import { PhotoView } from './photo-view';

export const OpenClaimPhotosFormFields = () => {
  const { t } = useTranslation();

  const { control, register, watch } = useFormContext<OpenClaimForm>();

  const { fields } = useFieldArray({
    control,
    name: 'photos',
  });

  const photos = watch('photos');

  return (
    <>
      <h4 className="mb-4 font-semibold text-gray-700">
        {t('claim.open_claim.step_photos.subtitle')}
      </h4>
      <div className="flex flex-wrap gap-2">
        {fields.map((photo, index) => {
          const registerSelectedPhotos = {
            ...register(`photos.${index}.selected`),
          };
          return (
            <label key={index}>
              <PhotoView
                photo={photo}
                isSelected={photos[index]?.selected ?? false}
              >
                <input
                  {...registerSelectedPhotos}
                  type="checkbox"
                  defaultChecked={photo.selected}
                  className="sr-only"
                />
              </PhotoView>
            </label>
          );
        })}
      </div>
    </>
  );
};
