import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { toast } from '@appchoose/toast';
import { format } from 'date-fns';
import { useAtom, useSetAtom } from 'jotai';

import { uploadFile } from '../../api/firebase';
import { motivesState } from '../../stores/group-message/motives';
import { selectedSaleState } from '../../stores/group-message/selectedSale';
import { selectedSegmentationState } from '../../stores/group-message/selectedSegmentation';
import { stepState } from '../../stores/group-message/step';
import {
  MessageGroupCanal,
  SegmentType,
  useGetReasonsToSendMessageToSegmentQuery,
  useSendMessageGroupMutation,
} from '../../types/generated';
import { SaleGroupMessageModalStepComplete } from './sale-group-message-step-complete';
import { SaleGroupMessageModalStepMotive } from './sale-group-message-step-motive';
import { SaleGroupMessageModalStepOptions } from './sale-group-message-step-options';
import { SaleGroupMessageModalStepSale } from './sale-group-message-step-sale';
import { SaleGroupMessageModalStepSegmentation } from './sale-group-message-step-segmentation';
import { SaleGroupMessageModalStepSummary } from './sale-group-message-step-summary';

import './sale-group-message-modal.scss';

export enum EstimatedDateReason {
  ChangeDate = 'change_date',
  PostponeDate = 'postpone_date',
  NoChange = 'no_change',
}

export type SaleGroupMessageForm = {
  motiveId: string;
  saleId: string;
  saleStoreId: string;
  segmentationId: SegmentType;
  segmentationOrderIds: string[];
  message: string;
  isCreditChecked: boolean;
  creditAmount: number;
  images: File[];
  estimatedDateReason: EstimatedDateReason;
  newEstimatedDeliveryStartDate: string;
  computedEstimatedDeliveryStartDate: Date;
  computedEstimatedDeliveryEndDate: Date;
  newEstimatedDeliveryPostponeDays: number;
};

type SaleGroupMessageModalProps = {
  userKey?: string;
  onClose: () => void;
};

export const SaleGroupMessageModal: React.FC<SaleGroupMessageModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useAtom(stepState);

  const setMotives = useSetAtom(motivesState);
  const setSelectedSale = useSetAtom(selectedSaleState);
  const setSelectedSegmentation = useSetAtom(selectedSegmentationState);

  const form = useForm<SaleGroupMessageForm>({
    mode: 'onChange',
    defaultValues: {
      estimatedDateReason: EstimatedDateReason.ChangeDate,
      segmentationId: SegmentType.AllOrdersOfSale,
    },
  });

  const { data: getReasonsToSendMessageData } =
    useGetReasonsToSendMessageToSegmentQuery();
  const [sendMessageGroupMutation, { error: sendMessageGroupError }] =
    useSendMessageGroupMutation();

  useEffect(() => {
    return () => {
      setStep(0);
      setSelectedSale(undefined);
      setSelectedSegmentation(undefined);
    };
  }, []);

  useEffect(() => {
    if (sendMessageGroupError) {
      toast.error('Une erreur est survenue en envoyant un message');
    }
  }, [sendMessageGroupError]);

  useEffect(() => {
    if (getReasonsToSendMessageData) {
      setMotives(
        getReasonsToSendMessageData.getReasonsToSendMessageToSegment.filter(
          (motive) => !!motive
        )
      );
    }
  }, [getReasonsToSendMessageData]);

  const onNextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setStep((prevStep) => prevStep + 1);
  };
  const onPreviousClick = () => {
    if (step === 0) {
      onClose();
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const uploadFilesBeforeSendingMessage = async (
    files: File[]
  ): Promise<string[]> => {
    const promises = files.map((file) => uploadFile(file));
    return Promise.all(promises);
  };

  const onSubmit = async (data: SaleGroupMessageForm) => {
    let files: string[] = [];
    if (data.images && data.images.length > 0) {
      files = await uploadFilesBeforeSendingMessage(data.images);
    }
    sendMessageGroupMutation({
      variables: {
        canal: MessageGroupCanal.Message,
        reasonId: data.motiveId,
        saleId: data.saleId,
        segmentId: data.segmentationId,
        content: data.message,
        options: {
          creditAmount: data.creditAmount ? data.creditAmount * 100 : undefined,
          deliveryDateRange:
            data.estimatedDateReason === EstimatedDateReason.ChangeDate &&
            data.computedEstimatedDeliveryStartDate
              ? {
                  start: format(
                    data.computedEstimatedDeliveryStartDate,
                    'yyyy-MM-dd'
                  ),
                  end: format(
                    data.computedEstimatedDeliveryEndDate,
                    'yyyy-MM-dd'
                  ),
                }
              : undefined,
          deliveryDateRangePostponeInDays:
            data.estimatedDateReason === EstimatedDateReason.PostponeDate &&
            data.newEstimatedDeliveryPostponeDays
              ? data.newEstimatedDeliveryPostponeDays
              : undefined,
          orderIds: data.segmentationOrderIds
            ? data.segmentationOrderIds
            : undefined,
        },
        photos: data.images && data.images.length > 0 ? files : undefined,
      },
    });
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="step-container flex size-full flex-col justify-between overflow-hidden"
      >
        <div className="relative flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          {step === 0 ? (
            <SaleGroupMessageModalStepMotive />
          ) : step === 1 ? (
            <SaleGroupMessageModalStepSale />
          ) : step === 2 ? (
            <SaleGroupMessageModalStepSegmentation />
          ) : step === 3 ? (
            <SaleGroupMessageModalStepOptions />
          ) : step === 4 ? (
            <SaleGroupMessageModalStepSummary />
          ) : step === 5 ? (
            <SaleGroupMessageModalStepComplete onClose={onClose} />
          ) : null}
        </div>
        {step === 5 ? null : (
          <footer className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
            <Button
              type="button"
              appearance="outline"
              onClick={onPreviousClick}
              className="border-none text-green-900"
            >
              {step === 0
                ? t('message_group.cancel')
                : t('message_group.previous')}
            </Button>
            {step === 4 ? (
              <Button
                appearance="primary"
                type="submit"
                disabled={!form.formState.isValid}
              >
                {t('message_group.confirm_and_send')}
              </Button>
            ) : (
              <Button
                appearance="primary"
                type="button"
                onClick={onNextClick}
                disabled={!form.formState.isValid}
              >
                {t('message_group.next')}
              </Button>
            )}
          </footer>
        )}
      </form>
    </Form>
  );
};

SaleGroupMessageModal.displayName = 'SaleGroupMessageModal';
