import { initializeApp } from 'firebase/app';
import type { NextOrObserver, User } from 'firebase/auth';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const configAdmin = {
  apiKey: import.meta.env.REACT_APP_FIREBASE_ADMIN_KEY as string,
  authDomain: import.meta.env.REACT_APP_FIREBASE_ADMIN_DOMAIN as string,
  databaseURL: import.meta.env.REACT_APP_FIREBASE_ADMIN_URL as string,
  projectId: import.meta.env.REACT_APP_FIREBASE_ADMIN_ID as string,
  storageBucket: import.meta.env.REACT_APP_FIREBASE_ADMIN_STOCKAGE as string,
  messagingSenderId: import.meta.env.REACT_APP_FIREBASE_ADMIN_SENDER as string,
};

const firebaseApp = initializeApp(configAdmin, 'admin');
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export const uploadFile = async (file: File) => {
  const storageRef = ref(
    storage,
    'support/' + uuidv4().replace(/-/g, '').slice(0, 25) + '/' + file.name
  );
  const uploadResult = await uploadBytes(storageRef, file);
  const downloadUrl = await getDownloadURL(uploadResult.ref);
  return downloadUrl;
};

export class FirebaseService {
  getToken() {
    const user = auth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }
  getUser() {
    return auth.currentUser;
  }
  get getAuth() {
    return auth;
  }
  authAdmin() {
    return getAuth(firebaseApp);
  }
  onStateChanged(callback: NextOrObserver<User | null>) {
    return this.getAuth.onAuthStateChanged(callback);
  }
  signOut() {
    return this.authAdmin().signOut();
  }
  signInWithPopup() {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/plus.login');
    provider.addScope('profile');
    provider.addScope('email');
    return signInWithPopup(this.authAdmin(), provider);
  }
  getUserUid() {
    const user = this.authAdmin().currentUser;
    return user ? user.uid : null;
  }
}
