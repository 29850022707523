import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateEffect } from '@react-hookz/web';
import { useAtomValue } from 'jotai';

import { ordersState } from '../../../../stores/orders';
import type { Item, Order } from '../../../../types/order';
import { transformPrice } from '../../../../utils/currency';
import type { RefundForm } from '../../../open-claim/open-claim';
import { RefundProduct } from './other-actions-refund-product';
import { RefundProductGroup } from './other-actions-refund-product-group';

type OtherActionsRefundStepSelectProductsProps = {
  order: Order | undefined;
  setSubmitTitle: (title: string) => void;
};

export const OtherActionsRefundStepSelectProducts = ({
  order,
  setSubmitTitle,
}: OtherActionsRefundStepSelectProductsProps) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setSubmitTitle(t('other_actions.refund.make_simulation'));
  }, []);

  const { control, resetField } = useFormContext<RefundForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'selected',
  });

  useEffect(() => {
    if (fields.length === 0) {
      resetField('percentage');
    }
  }, [fields]);

  const orders = useAtomValue(ordersState);

  const [currentOrder] = useState(
    orders.find((current) => current.id === order?.id)
  );
  const [products] = useState<Item[]>(currentOrder?.items ?? []);
  const [unselectedProducts, setUnselectedProducts] = useState<Item[]>([
    ...products.filter(
      (product) =>
        fields.findIndex((field) => field.product.id === product.id) === -1
    ),
  ]);

  useUpdateEffect(() => {
    setUnselectedProducts([
      ...products.filter(
        (product) =>
          fields.findIndex((field) => field.product.id === product.id) === -1
      ),
    ]);
  }, [products, fields]);

  const handleSelectProduct = (selected: Item) => {
    append({ product: selected });
  };

  const handleUnselectProduct = (unselected: Item) => {
    remove(fields.findIndex((field) => field.product.id === unselected.id));
  };

  return (
    <div className="space-y-4">
      <p className="font-semibold text-gray-700">
        {t('other_actions.refund.select_products')}
      </p>
      <RefundProductGroup
        fields={fields}
        handleUnselectProduct={handleUnselectProduct}
      />
      {unselectedProducts.map((product) => (
        <div className="rounded border border-gray-700 p-4" key={product.id}>
          <RefundProduct
            checkboxIdentifier={`${product.id}-unselected`}
            handleSelectProduct={() => handleSelectProduct(product)}
            image={product.imageUrl}
            priceSold={transformPrice(
              product.priceSold.valueWithVat,
              i18n.language === 'fr' ? 'fr' : 'en',
              product.priceSold.currency || 'EUR'
            )}
            refundAmount={product.refundSummary.refundedAmountInPercentage}
            size={product.size}
            title={product.name}
          />
        </div>
      ))}
    </div>
  );
};
