import { atom } from 'jotai';

import type { CategoryData, TemplateData } from '../types/generated';

export const categoriesState = atom<CategoryData[] | null | undefined>(
  undefined
);

export const templatesState = atom<TemplateData[] | null | undefined>(
  undefined
);

export const templatesMapState = atom((get) => {
  const categories = get(categoriesState);
  const templates = get(templatesState);

  const templatesMap = [] as {
    key: string;
    name: string;
    data: TemplateData[];
  }[];

  categories?.forEach((category) => {
    if (typeof category.id !== 'undefined') {
      templatesMap.push({
        key: `##${category.id ?? 0}`,
        name: category.name ?? '',
        data: [],
      });
    }
  });

  templates?.forEach((template) => {
    if (typeof template.category !== 'undefined') {
      templatesMap
        .find((category) => `##${template.category ?? 0}` === category.key)
        ?.data.push(template);
    }
  });

  return templatesMap;
});
