import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import Checkbox from '@appchoose/checkbox';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabelCheckbox,
} from '@appchoose/form';
import Loader from '@appchoose/loader';

import {
  CashbackStatus,
  useCreateClaimIntentQuery,
} from '../../types/generated';
import type { Order } from '../../types/order';
import { hasPostActionCashbackAmountChanged } from '../../utils/cashback';
import { transformPrice } from '../../utils/currency';
import type { OtherActionsForm } from './other-actions-modal';
import { PostActionCashbackAmountMessage } from './post-action-cashback-amount-message';

type OtherActionsModalConfirmationDeleteProps = {
  order?: Order;
};

export const OtherActionsModalConfirmationDelete = ({
  order,
}: OtherActionsModalConfirmationDeleteProps) => {
  const { i18n, t } = useTranslation();

  const form = useFormContext<OtherActionsForm>();

  const { data: createClaimIntentData, loading: createClaimIntentLoading } =
    useCreateClaimIntentQuery({
      variables: {
        orderId: order?.id ?? '',
        deltaAmount: form
          .getValues('products')
          .filter((product) => product.selected)
          .map((product) => product.product.priceSold.valueWithVat)
          .reduce((partialSum, a) => partialSum + a, 0),
      },
    });

  const [selectedProducts] = useState(
    form
      .getValues('products')
      .filter((p) => p.selected)
      .map((p) => p.product)
  );

  const [selectedAmount] = useState(
    selectedProducts.reduce((a, b) => a + b.priceSold.valueWithVat, 0)
  );

  return (
    <div className="space-y-4">
      <div className="text-sm text-gray-700">
        {t('other_actions.step_confirmation.automatic_refund')}
      </div>
      {!createClaimIntentLoading ? (
        <Alert
          appearance="warning"
          title={transformPrice(
            selectedAmount,
            i18n.language === 'fr' ? 'fr' : 'en',
            order?.totalPriceSold.currency ?? 'EUR'
          )}
        >
          <>
            <p className="text-sm text-gray-700">
              {t('other_actions.step_confirmation.articleWithCount', {
                count: selectedProducts.length,
              })}
              , {t('other_actions.step_confirmation.shipping_fee')}.
            </p>
            {order?.cashback &&
              order.cashback.status === CashbackStatus.Pending &&
              createClaimIntentData?.createClaimIntent &&
              hasPostActionCashbackAmountChanged(
                createClaimIntentData.createClaimIntent
              ) && (
                <>
                  <div className="my-4 border-t border-beige-400"></div>
                  <span className="text-sm text-gray-700">
                    {t('other_actions.step_action.cashback_will_be_updated')}
                  </span>
                  <div className="text-sm font-semibold text-gray-700">
                    <PostActionCashbackAmountMessage
                      cashbackAmount={order.cashback.amount}
                      cashbackCurrency={order.cashback.currency}
                      currentAmount={
                        createClaimIntentData.createClaimIntent.currentAmount
                      }
                      postActionAmount={
                        createClaimIntentData.createClaimIntent.postActionAmount
                      }
                    />
                  </div>
                </>
              )}
          </>
        </Alert>
      ) : (
        <div className="mb-6">
          <Loader className="size-6" />
        </div>
      )}
      {form.getValues('cancelOrderReason') === 'dont_want_it' &&
      order &&
      !order.isFullDigital ? (
        <FormField
          control={form.control}
          name="putBackInStock"
          render={({ field }) => (
            <FormItem>
              <div className="flex space-x-3">
                <FormControl>
                  <Checkbox
                    {...field}
                    value=""
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabelCheckbox>
                  {t('other_actions.step_confirmation.put_back_in_stock')}
                </FormLabelCheckbox>
              </div>
            </FormItem>
          )}
        />
      ) : null}
    </div>
  );
};
