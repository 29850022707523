import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import { useAtomValue, useSetAtom } from 'jotai';

import { imageViewerState } from '../../stores/imageViewerState';
import { ordersState } from '../../stores/orders';
import type { ThreadMessageGroup } from '../../stores/selected';
import { selectedOrderIdActiveState } from '../../stores/selected';
import {
  AttachmentFileType,
  type ConversationMessageGroupItemConcern,
} from '../../types/generated';
import { transformDateMessage, transformHours } from '../../utils/date';
import { scrollIntoOrder } from '../../utils/order';
import {
  getFlowReasonMessageFromFlow,
  isClaimPending,
} from '../../utils/utils';
import { FocusOrder } from '../icons/focus-order';
import { ChatMessageGroupFooter } from './chat-message-group-footer';

type ChatMessageGroupProps = {
  message: ThreadMessageGroup;
};

type ItemsGroupedByVariants = {
  item: ConversationMessageGroupItemConcern;
  quantity: number;
};

export const ChatMessageGroup: React.FC<ChatMessageGroupProps> = ({
  message,
}: ChatMessageGroupProps) => {
  const { i18n, t } = useTranslation();

  const orders = useAtomValue(ordersState);
  const setImageViewer = useSetAtom(imageViewerState);

  const setSelectedOrderIdActive = useSetAtom(selectedOrderIdActiveState);

  const linkedOrder = useMemo(
    () => orders.find((order) => order.id === message.order.id),
    [message, orders]
  );

  const [itemsGrouped] = useState<ItemsGroupedByVariants[]>(
    message.order.itemsConcern?.reduce<ItemsGroupedByVariants[]>(
      (accumulator, item) => {
        let itemGrouped = accumulator.find(
          (itemGrouped) => itemGrouped.item.variantId === item.variantId
        );

        if (!itemGrouped) {
          itemGrouped = { item, quantity: 0 };
          accumulator.push(itemGrouped);
        }

        itemGrouped.quantity++;

        return accumulator;
      },
      []
    ) ?? []
  );

  const getOrderStatusContainerColor = () => {
    if (linkedOrder?.claims[0]?.isAccepted)
      return 'bg-[#ECF7F1] border-green-200 hover:bg-[#C6E7D5]';
    if (isClaimPending(linkedOrder?.claims[0]))
      return 'bg-orange-300 border-[#ECC285] hover:bg-[#F6E1C2]';

    return 'bg-gray-50 border-gray-300 hover:bg-gray-100';
  };

  const getOrderStatusIconColor = () => {
    if (linkedOrder?.claims?.[0]?.isAccepted) return 'text-green-600';
    if (isClaimPending(linkedOrder?.claims?.[0])) return 'text-orange-600';

    return 'text-gray-900';
  };

  const images = message.attachments.filter(
    (attachment) => attachment.fileType === AttachmentFileType.Image
  );
  const documents = message.attachments.filter(
    (attachment) => attachment.fileType === AttachmentFileType.Document
  );

  return (
    <li className="message space-y-2">
      {message.displayTime ? (
        <p className="timer mb-4 mt-8 text-center text-xs text-gray-500">
          {transformDateMessage(
            new Date(message.createdAt || ''),
            i18n.language === 'fr' ? 'fr' : 'en'
          )}
        </p>
      ) : null}
      <div className="body space-y-4 rounded-xl rounded-tl-sm border border-gray-100 bg-[#FBFBFB] p-4 text-gray-700">
        <div
          className={`cursor-pointer rounded-lg border p-4 ${getOrderStatusContainerColor()}`}
          onClick={() => {
            setSelectedOrderIdActive(message.order.id);
            scrollIntoOrder(message.order.id);
          }}
        >
          <div className="flex flex-row justify-between">
            <p className="font-semibold text-gray-900">
              {getFlowReasonMessageFromFlow(message.reason, t)}
            </p>
            <div className="ml-2 min-w-max">
              <FocusOrder className={getOrderStatusIconColor()} />
            </div>
          </div>
          <p className="mt-1 text-sm text-gray-900">
            {message.order.brandName}
          </p>
          {message.order.itemsConcern &&
          message.order.itemsConcern.length > 0 ? (
            <>
              {itemsGrouped.map(({ item, quantity }, key) => (
                <div className="mt-2 flex flex-row items-center" key={key}>
                  <div className="relative flex size-7 shrink-0 rounded-sm">
                    <img src={item.image ?? ''} className="size-7 rounded-sm" />
                    {quantity > 1 ? (
                      <p className="absolute left-0 top-0.5 bg-black p-0.5 text-xxs font-semibold text-white">{`×${quantity}`}</p>
                    ) : null}
                  </div>
                  <div className="ml-2">
                    <p className="line-clamp-1 text-xs">{item.description}</p>
                    {item.options.length ? (
                      <p className="line-clamp-1 text-xs text-gray-500">
                        {item.options.join(' - ')}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
        <p className="whitespace-pre-line text-sm">{message.body}</p>
        {images.length > 0 || documents.length > 0 ? (
          <div className="flex flex-row flex-wrap gap-2">
            {images.map((image, key) => (
              <img
                src={image.url}
                className="size-12 cursor-pointer rounded object-cover"
                onClick={() =>
                  setImageViewer({
                    slides: images.map((photo, index) => ({
                      index: index,
                      src: photo.url,
                    })),
                    initialIndex: key,
                  })
                }
                key={key}
              />
            ))}
            {documents.map((document, key) => (
              <a
                href={document.url}
                target="_blank"
                className="rounded border border-gray-100 bg-gray-50 p-3"
                key={key}
              >
                <Icon icon="document" className="size-6" />
              </a>
            ))}
          </div>
        ) : null}
        <ChatMessageGroupFooter
          message={message}
          claim={linkedOrder?.claims[0]}
        />
      </div>
      <p className="timer text-xs text-gray-500">
        {transformHours(
          new Date(message.createdAt || ''),
          i18n.language === 'fr' ? 'fr' : 'en'
        )}
      </p>
    </li>
  );
};
