import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@appchoose/button';
import Loader from '@appchoose/loader';

import logoBlack from '../../assets/logo-black.svg';
import logoWhite from '../../assets/logo-white.svg';
import { useAuth } from '../../stores/authcontext';

export const LoginScreen: React.FC = () => {
  const { isAuthenticationLoading, signIn, signOut } = useAuth();
  const navigate = useNavigate();

  const login = async () => {
    const loggedIn = await signIn();

    if (loggedIn) {
      navigate('/', { replace: true });
    } else {
      alert('Un email @appchoose.io est nécessaire.');
      signOut();
    }
  };

  const logo =
    import.meta.env.REACT_APP_STAGE === 'production' ? logoBlack : logoWhite;

  return (
    <div className="text-center">
      <img src={logo} className="mb-10 mt-24 inline-block size-10" />
      {!isAuthenticationLoading ? (
        <div className="mx-auto w-full p-8 text-center">
          <h2 className="mb-4 text-xl font-bold text-gray-900">
            Welcome back!
          </h2>
          <p className="mb-6 text-xl text-gray-700">Sign in to continue</p>
          <Button type="button" onClick={login}>
            Sign in with your @appchoose.io
          </Button>
        </div>
      ) : (
        <div className="flex justify-center">
          <Loader className="my-4 h-8 w-8" />
        </div>
      )}
    </div>
  );
};
