import { useEffect } from 'react';

import { useAtom, useAtomValue } from 'jotai';

import { ordersState } from '../stores/orders';
import { selectedState } from '../stores/selected';
import {
  useOrderLazyQuery,
  useOrderUpdatedSubscription,
} from '../types/generated';

export const useListenOrderUpdated = () => {
  const selected = useAtomValue(selectedState);
  const [orders, setOrders] = useAtom(ordersState);

  const { data: orderUpdatedData } = useOrderUpdatedSubscription({
    variables: { customerId: selected?.user?.userKey ?? '' },
  });
  const [getOrder] = useOrderLazyQuery();

  useEffect(() => {
    const data = orderUpdatedData?.orderUpdated;
    if (data) {
      const foundIndex = orders.findIndex((_order) => _order.id === data.id);
      if (foundIndex === -1) {
        return;
      }

      getOrder({ variables: { orderId: data.id } }).then((res) => {
        if (!res.data) return;

        const foundIndex = orders.findIndex(
          (_order) => _order.id === res.data?.order?.id
        );
        if (foundIndex === -1) {
          return;
        }
        const newOrders = orders.slice();
        newOrders[foundIndex] = res.data.order;
        setOrders(newOrders);
      });
    }
  }, [getOrder, orderUpdatedData]);
};
