import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Label from '@appchoose/label';
import Loader from '@appchoose/loader';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';
import Select, {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';
import * as Accordion from '@radix-ui/react-accordion';

import {
  RefundOrderCoverInput,
  RefundOrderReasonInput,
  useRefundOrderItemsPartiallyMutation,
} from '../../../../types/generated';
import type { Order } from '../../../../types/order';
import { formatPrice, transformPrice } from '../../../../utils/currency';
import type { RefundForm } from '../../../open-claim/open-claim';
import { BlockInfo } from '../../block-info';

type OtherActionsRefundStepSimulationProps = {
  order: Order | undefined;
  setSubmitTitle: (title: string) => void;
};

export const OtherActionsRefundStepSimulation = ({
  order,
  setSubmitTitle,
}: OtherActionsRefundStepSimulationProps) => {
  const { i18n, t } = useTranslation();
  const { getValues, control, watch } = useFormContext<RefundForm>();
  const { fields } = useFieldArray({
    control,
    name: 'selected',
  });

  const [
    refundOrderItems,
    {
      loading: refundOrderItemsPartiallyLoading,
      data: refundOrderItemsPartiallyData,
      error: refundOrderItemsPartiallyError,
    },
  ] = useRefundOrderItemsPartiallyMutation();

  useEffect(() => {
    setSubmitTitle(t('other_actions.refund.refund'));
  }, []);

  useEffect(() => {
    refundOrderItems({
      variables: {
        orderId: order?.id ?? '',
        input: {
          apply: false,
          refundIntents: fields.map((field) => {
            return {
              itemId: field.product.id,
              percentageToRefund: getValues('percentage'),
            };
          }),
        },
      },
    });
  }, [fields]);

  const getRefundAmount = () =>
    ((refundOrderItemsPartiallyData?.refundOrderItemsPartially
      .cashAmountInCents ?? 0) +
      (refundOrderItemsPartiallyData?.refundOrderItemsPartially
        .creditAmountInCents ?? 0)) /
    100;

  const initiatorOptions = [
    {
      value: RefundOrderCoverInput.Supplier,
      label: t('other_actions.refund.refund_by_options.supplier', {
        brand_name: order?.seller?.name,
      }),
    },
    {
      value: RefundOrderCoverInput.Choose,
      label: t('other_actions.refund.refund_by_options.choose'),
    },
  ];

  const refundReasonOptions = [
    {
      value: RefundOrderReasonInput.ClaimDeadlineExceeded,
      label: t('other_actions.refund.refund_reasons.claim_deadline_exceeded'),
    },
    {
      value: RefundOrderReasonInput.ClaimRefused,
      label: t('other_actions.refund.refund_reasons.claim_refused'),
    },
    {
      value: RefundOrderReasonInput.CommercialGesture,
      label: t('other_actions.refund.refund_reasons.commercial_gesture'),
    },
    {
      value: RefundOrderReasonInput.ItemWrongImage,
      label: t('other_actions.refund.refund_reasons.item_wrong_image'),
    },
    {
      value: RefundOrderReasonInput.ItemWrongPrice,
      label: t('other_actions.refund.refund_reasons.item_wrong_price'),
    },
    {
      value: RefundOrderReasonInput.ReturnDeadlineExceeded,
      label: t('other_actions.refund.refund_reasons.return_deadline_exceeded'),
    },
    {
      value: RefundOrderReasonInput.ReturnLocked,
      label: t('other_actions.refund.refund_reasons.return_locked'),
    },
    {
      value: RefundOrderReasonInput.ReturnRefused,
      label: t('other_actions.refund.refund_reasons.return_refused'),
    },
  ];

  watch('initiator');

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <p className="font-semibold text-gray-700">
          {t('other_actions.refund.select_products')}
        </p>
        <div className="space-y-4 rounded border border-gray-700 p-4">
          {fields.map((field) => (
            <div className="flex flex-row items-center" key={field.id}>
              <div className="relative flex size-14 items-center overflow-hidden rounded border border-gray-300">
                <img src={field.product.imageUrl} alt="" className="size-14" />
                <p className="absolute inset-0 flex items-center justify-center bg-black/50 text-sm font-bold text-white">
                  -{getValues('percentage')}%
                </p>
              </div>
              <div className="ml-3 grow space-y-1">
                <p className="text-sm">{field.product.name}</p>
                <p className="text-xs text-gray-700">
                  {t('other_actions.refund.purchase_price', {
                    purchase_price: transformPrice(
                      field.product.priceSold.valueWithVat,
                      i18n.language === 'fr' ? 'fr' : 'en',
                      field.product.priceSold.currency || 'EUR'
                    ),
                  })}
                </p>
              </div>
            </div>
          ))}

          {/* SIMULATION EN COURS */}
          {refundOrderItemsPartiallyLoading ? (
            <div className="flex flex-row items-center space-x-2">
              <p className="text-sm font-semibold text-green-900">
                {t('other_actions.refund.simulation_in_progress')}
              </p>
              <Loader className="h-4 w-4" />
            </div>
          ) : null}

          {/* SIMULATION ERROR */}
          {refundOrderItemsPartiallyError ? (
            <BlockInfo
              title={t('other_actions.refund.error_title')}
              type="error"
            >
              <p className="mt-1 text-sm">
                {t('other_actions.refund.error_description_refresh')}
              </p>
            </BlockInfo>
          ) : null}

          {/* SIMULATION INFO */}
          {refundOrderItemsPartiallyData ? (
            <BlockInfo
              title={t('other_actions.refund.possible_refund')}
              type="warning"
            >
              <>
                <p className="mt-1 text-2xl font-semibold">
                  {formatPrice(
                    getRefundAmount(),
                    i18n.language === 'fr' ? 'fr' : 'en',
                    order?.totalPriceSold.currency ?? 'EUR'
                  )}
                </p>
                <p className="mt-1 text-sm">
                  {t('other_actions.refund.refund_detail', {
                    cash: formatPrice(
                      refundOrderItemsPartiallyData.refundOrderItemsPartially
                        .cashAmountInCents / 100,
                      i18n.language === 'fr' ? 'fr' : 'en',
                      order?.totalPriceSold.currency ?? 'EUR'
                    ),
                    credits: formatPrice(
                      refundOrderItemsPartiallyData.refundOrderItemsPartially
                        .creditAmountInCents / 100,
                      i18n.language === 'fr' ? 'fr' : 'en',
                      order?.totalPriceSold.currency ?? 'EUR'
                    ),
                  })}
                </p>
                {getRefundAmount() < 1 ? (
                  <div className="mt-5">
                    <Icon icon="alert" size="large" />
                    <p className="mt-2 text-sm">
                      <Trans i18nKey="other_actions.refund.less_than_one_euro.information" />
                    </p>

                    <Accordion.Root type="single" collapsible>
                      <Accordion.Item value="example">
                        <Accordion.Trigger className="group mt-2 flex cursor-pointer flex-row">
                          <span className="text-xs font-semibold text-green-900">
                            {t(
                              'other_actions.refund.less_than_one_euro.example_wrapper_title'
                            )}
                          </span>
                          <Icon
                            icon="arrowDown"
                            className="ml-1 text-green-900 transition-transform group-data-[state=open]:rotate-180"
                          />
                        </Accordion.Trigger>
                        <Accordion.Content>
                          <div className="mt-5">
                            <p className="text-sm">
                              <Trans i18nKey="other_actions.refund.less_than_one_euro.example_brand" />
                            </p>
                            <p className="text-sm">
                              <Trans i18nKey="other_actions.refund.less_than_one_euro.example_client" />
                            </p>
                            <p className="mt-5 text-sm">
                              <Trans i18nKey="other_actions.refund.less_than_one_euro.example_headline" />
                            </p>
                            <p className="text-sm">
                              <Trans i18nKey="other_actions.refund.less_than_one_euro.example_usecase_1" />
                            </p>
                            <p className="text-sm">
                              <Trans i18nKey="other_actions.refund.less_than_one_euro.example_usecase_2" />
                            </p>
                            <ul>
                              <li className="ml-5 list-disc text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_bullet_1" />
                              </li>
                              <li className="ml-5 list-disc text-sm">
                                <Trans i18nKey="other_actions.refund.less_than_one_euro.example_bullet_2" />
                              </li>
                            </ul>
                            <p className="mt-5 text-sm">
                              <Trans i18nKey="other_actions.refund.less_than_one_euro.example_note" />
                            </p>
                          </div>
                        </Accordion.Content>
                      </Accordion.Item>
                    </Accordion.Root>
                  </div>
                ) : null}
              </>
            </BlockInfo>
          ) : null}
        </div>
      </div>
      <FormField
        control={control}
        name="initiator"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormItem className="space-y-4">
            <Label htmlFor="initiator" className="font-semibold text-gray-700">
              {t('other_actions.refund.refund_by')}
            </Label>
            <FormControl>
              <RadioGroup
                id="initiator"
                value={value}
                onValueChange={(value) => onChange(value)}
                className="gap-4"
              >
                {initiatorOptions.map((option) => (
                  <div
                    className="group flex items-center space-x-3"
                    key={option.value}
                  >
                    <RadioGroupItem
                      id={`initiator-option-${option.value}`}
                      key={option.value}
                      value={option.value}
                    />
                    <Label
                      htmlFor={`initiator-option-${option.value}`}
                      className="cursor-pointer text-sm font-semibold text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                    >
                      {option.label}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
      {getValues('initiator') === RefundOrderCoverInput.Choose ? (
        <FormField
          control={control}
          name="refundReason"
          rules={{
            required: true,
          }}
          render={({
            field: { onChange, value, ref },
            fieldState: { invalid },
          }) => (
            <FormItem className="max-w-80">
              <FormLabel>
                {t('other_actions.refund.refund_reason.label')}
              </FormLabel>
              <FormControl>
                <Select onValueChange={onChange} value={value}>
                  <SelectTrigger ref={ref} aria-invalid={invalid}>
                    <SelectValue
                      placeholder={t(
                        'other_actions.refund.refund_reason.empty_field'
                      )}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {refundReasonOptions
                      .toSorted((a, b) => a.label.localeCompare(b.label))
                      .map((option) => (
                        <SelectItem value={option.value} key={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage match="required">
                {t(
                  'other_actions.refund.refund_reason.validation_errors.required'
                )}
              </FormMessage>
            </FormItem>
          )}
        />
      ) : null}
    </div>
  );
};
