import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import type { TFunction } from 'i18next';

import { OrderTagClaim } from '../../types/generated';
import { OrderBadge } from './order-badge';
import { getOrderClaimLabelFromTag } from './order-claim-badge';

export const getOrderClaimFullLabelFromTag = (
  tag: OrderTagClaim,
  t: TFunction
) => {
  switch (tag) {
    case OrderTagClaim.Opened:
      return t('order.status.claim.claim_open');
    case OrderTagClaim.Closed:
      return t('order.status.claim.claim_closed');
  }
};

export const variant = ({
  isCancelled,
  tag,
}: {
  isCancelled?: boolean;
  tag: OrderTagClaim;
}) => {
  if (isCancelled) return 'filled';
  return tag === OrderTagClaim.Opened ? 'danger' : 'filled';
};

export const ModalOrderClaimBadge: React.FC<{
  isCancelled?: boolean;
  tag: OrderTagClaim;
  fullLabel?: boolean;
}> = ({ isCancelled, tag, fullLabel = false }) => {
  const { t } = useTranslation();

  if (tag === OrderTagClaim.None) return null;

  return (
    <OrderBadge style={variant({ isCancelled, tag })} intent="bold">
      <div className="flex items-center gap-x-1">
        <Icon icon="complains" className="!size-3.5" />
        <span className={cn({ 'line-through': isCancelled })}>
          {fullLabel
            ? getOrderClaimFullLabelFromTag(tag, t)
            : getOrderClaimLabelFromTag(tag, t)}
        </span>
      </div>
    </OrderBadge>
  );
};
