/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import Input from '@appchoose/input';
import { ModalFooter, ModalHeader, ModalTitle } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import { useSetAtom } from 'jotai';

import { selectedState } from '../../stores/selected';
import { useChangeReferralMutation } from '../../types/generated';
import type { IUser } from '../../types/user';

type ReferralModalProps = {
  user?: IUser;
  referral_code: string;
  onClose: () => void;
};

type ReferralForm = {
  referralCode: string;
};

export const ReferralModal: React.FC<ReferralModalProps> = ({
  user,
  referral_code,
  onClose,
}: ReferralModalProps) => {
  const { t } = useTranslation();

  const setSelected = useSetAtom(selectedState);

  const [changeReferral, { data, loading, error }] =
    useChangeReferralMutation();

  useEffect(() => {
    if (error) {
      toast.error(t('referral.error'));
    }
  }, [error]);

  useEffect(() => {
    // @TODO : temporary until back-end updates the mutation to return the error the proper way
    if ((data?.changeReferral?.res as boolean) === false) {
      toast.error(t('referral.error'));
      return;
    }
    if (data) {
      toast.success(t('referral.success'));
    }
  }, [data]);

  const form = useForm<ReferralForm>({
    mode: 'onTouched',
    defaultValues: {
      referralCode: referral_code,
    },
  });

  const onSubmit = async (data: ReferralForm) => {
    try {
      await changeReferral({
        variables: {
          userKey: user?.userKey ?? '',
          referral: data.referralCode,
        },
      });

      setSelected((prev) =>
        prev
          ? {
              ...prev,
              user: {
                ...prev?.user,
                referralCode: data.referralCode,
              },
            }
          : undefined
      );

      onClose();
    } catch {
      // Do nothing
    }
  };

  const formatter = (str: string) => {
    return str
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between overflow-hidden"
      >
        <div className="flex flex-auto flex-col overflow-y-auto p-6 md:p-10">
          <ModalHeader>
            <ModalTitle>{t('referral.title')}</ModalTitle>
          </ModalHeader>
          <div className="space-y-6">
            <p className="text-sm text-gray-700">
              {t('referral.current_referral_code', { name: user?.name })}{' '}
              <b>{referral_code}.</b>
            </p>
            <FormField
              control={form.control}
              name="referralCode"
              rules={{
                required: true,
                minLength: 4,
                maxLength: 16,
                pattern: /^[A-Z0-9]+$/i,
                validate: {
                  different: (v) => v !== referral_code,
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('referral.fields.referral_code.label')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder={t(
                        'referral.fields.referral_code.placeholder'
                      )}
                      {...field}
                      onChange={(e) => {
                        const { value } = e.target;

                        e.target.value = formatter(value);

                        field.onChange(e);
                      }}
                    />
                  </FormControl>
                  <FormMessage match="required">
                    {t(
                      'referral.fields.referral_code.validation_errors.required'
                    )}
                  </FormMessage>
                  <FormMessage match="minLength">
                    {t(
                      'referral.fields.referral_code.validation_errors.minLength',
                      {
                        minLength: '4',
                      }
                    )}
                  </FormMessage>
                  <FormMessage match="maxLength">
                    {t(
                      'referral.fields.referral_code.validation_errors.maxLength',
                      {
                        maxLength: '16',
                      }
                    )}
                  </FormMessage>
                  <FormMessage match="pattern">
                    {t(
                      'referral.fields.referral_code.validation_errors.pattern'
                    )}
                  </FormMessage>
                  <FormMessage match="different">
                    {t(
                      'referral.fields.referral_code.validation_errors.different'
                    )}
                  </FormMessage>
                </FormItem>
              )}
            />

            <div className="space-y-2 text-xs text-gray-500">
              <p>{t('referral.directions')}</p>
              <ol className="space-y-2">
                <li className="flex items-center">
                  <Icon icon="check" className="mr-1" />
                  {t('referral.allowed_characters')}
                </li>
                <li className="flex items-center">
                  <Icon icon="close" className="mr-1" />
                  {t('referral.not_allowed_characters')}
                </li>
              </ol>
            </div>
          </div>
        </div>

        <ModalFooter>
          <Button
            type="button"
            appearance="outline"
            className="border-none text-green-900"
            onClick={onClose}
          >
            {t('cancel')}
          </Button>
          <Button appearance="primary" type="submit" disabled={loading}>
            {t('edit')}
          </Button>
        </ModalFooter>
      </form>
    </Form>
  );
};
