import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';
import * as Accordion from '@radix-ui/react-accordion';

import type { Item } from '../../types/order';
import { transformPrice } from '../../utils/currency';
import { RefundChoose } from '../icons/refund-choose';
import { ProductModal } from '../product-modal/product-modal';

export type OrderProductDetailProps = {
  item: Item;
  orderBrandUrl: string;
  quantity: number;
  returnStatus?: string;
};

export const OrderProductDetail: React.FC<OrderProductDetailProps> = ({
  item,
  orderBrandUrl,
  quantity,
  returnStatus,
}: OrderProductDetailProps) => {
  const { i18n, t } = useTranslation();

  return (
    <Modal>
      <ModalTrigger asChild>
        <div className="relative flex cursor-pointer flex-row items-start space-x-4">
          <img
            src={item.imageUrl}
            className={cn(
              'size-15 shrink-0 rounded border-0.5 border-gray-300 object-cover',
              {
                'opacity-25': item.isCancelled,
              }
            )}
          />
          <div className="min-w-0">
            <p
              className={cn(`text-sm leading-5.5`, {
                'text-gray-500': item.isCancelled,
              })}
            >
              {item.name}
            </p>

            {quantity > 1 ? (
              <p className="absolute left-px top-2 rounded-r-sm bg-gray-900 px-1 py-0.5 text-xs font-semibold text-white">
                x<span>{quantity}</span>
              </p>
            ) : null}

            {item.size ? (
              <p
                className={cn('text-xs', {
                  'text-gray-300': item.isCancelled,
                  'text-gray-700': !item.isCancelled,
                })}
              >
                {t('order.product.size')} <span>{item.size}</span>
              </p>
            ) : null}

            {item.priceSold ? (
              <p
                className={cn('text-xs', {
                  'text-gray-300': item.isCancelled,
                  'text-gray-500': !item.isCancelled,
                })}
              >
                {transformPrice(
                  item.priceSold.valueWithVat,
                  i18n.language === 'fr' ? 'fr' : 'en',
                  item.priceSold.currency
                )}
              </p>
            ) : null}

            {!item.isReturnable ? (
              <p
                className={cn('text-xs', {
                  'text-orange-200': item.isCancelled,
                  'text-orange-600': !item.isCancelled,
                })}
              >
                {t('order.product.not_returnable')}
              </p>
            ) : null}

            {(item.newDigitalCouponCode ?? item.digitalCouponCode) ? (
              <p
                className={cn('break-all text-xs uppercase', {
                  'text-gray-300': item.isCancelled,
                  'text-gray-700': !item.isCancelled,
                })}
              >
                {item.newDigitalCouponCode ?? item.digitalCouponCode}
              </p>
            ) : null}

            <div className="space-y-1">
              {!item.isCancelled && returnStatus ? (
                <p className="border-l-2 border-gray-100 pl-2 text-xs font-bold text-gray-700">
                  {returnStatus}
                </p>
              ) : null}

              {item.refundSummary.refundedAmountInPercentage &&
              !item.isCancelled ? (
                <Accordion.Root
                  type="single"
                  disabled={
                    !item.refundSummary
                      .refundedAmountInPercentageCoveredByChoose &&
                    !!item.refundSummary
                      .refundedAmountInPercentageCoveredBySupplier
                  }
                  collapsible
                  className="mt-1 border-l border-gray-100 pl-2 text-xs font-semibold text-orange-600"
                >
                  <Accordion.Item value="refund-details">
                    <Accordion.Header>
                      <Accordion.Trigger
                        className="group flex w-full items-center space-x-1"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <span className="truncate">
                          {item.refundSummary.isTotallyRefunded
                            ? t('order.refunded')
                            : t('order.x_refunded', {
                                amount:
                                  item.refundSummary.refundedAmountInPercentage,
                              })}
                        </span>
                        {item.refundSummary
                          .refundedAmountInPercentageCoveredByChoose !== 0 &&
                        item.refundSummary
                          .refundedAmountInPercentageCoveredBySupplier === 0 ? (
                          <Tooltip>
                            <TooltipTrigger>
                              <RefundChoose className="text-orange-600" />
                            </TooltipTrigger>
                            <TooltipContent sideOffset={8}>
                              <div className="px-1.5 py-3 text-xs font-semibold">
                                <p className="text-center">
                                  {t('order.refund.payable_by_choose')}
                                </p>
                              </div>
                              <TooltipArrow />
                            </TooltipContent>
                          </Tooltip>
                        ) : null}
                        <Icon
                          icon="arrowDown"
                          className="transition-transform group-data-[disabled]:hidden group-data-[state=open]:rotate-180"
                        />
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content>
                      <div className="ml-2 mt-1 space-y-1 text-xs font-normal text-gray-900">
                        {item.refundSummary
                          .refundedAmountInPercentageCoveredByChoose &&
                        item.refundSummary
                          .refundedAmountInPercentageCoveredBySupplier ? (
                          <p>
                            {t('order.x_refunded_by_choose', {
                              amount:
                                item.refundSummary
                                  .refundedAmountInPercentageCoveredByChoose,
                            })}
                          </p>
                        ) : null}
                        {item.refundSummary
                          .refundedAmountInPercentageCoveredByChoose &&
                        item.refundSummary
                          .refundedAmountInPercentageCoveredBySupplier ? (
                          <p>
                            {t('order.x_refunded_by_supplier', {
                              amount:
                                item.refundSummary
                                  .refundedAmountInPercentageCoveredBySupplier,
                            })}
                          </p>
                        ) : null}
                        <a
                          href={orderBrandUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-start font-semibold text-green-900"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <span>{t('order.see_details')}</span>
                          <Icon
                            icon="externalLinkSimple"
                            className="inline-block h-3.5 w-3.5"
                          />
                        </a>
                      </div>
                    </Accordion.Content>
                  </Accordion.Item>
                </Accordion.Root>
              ) : null}

              {item.isCancelled ? (
                <p className="border-l-2 border-gray-100 pl-2 text-xs font-semibold text-orange-600">
                  {t('sidebar.cancelled')}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </ModalTrigger>
      <ModalContent scrollable>
        <ProductModal productId={item.productId} />
      </ModalContent>
    </Modal>
  );
};

OrderProductDetail.displayName = 'OrderProductDetail';
