import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Label from '@appchoose/label';
import RadioGroup, { RadioGroupItem } from '@appchoose/radio-group';

import type { ClaimReason } from '../../types/generated';
import type { ClaimOption, OpenClaimForm } from './open-claim';

type OpenClaimReasonFormFieldsProps = {
  options: ClaimOption[];
};

export const OpenClaimReasonFormFields = ({
  options,
}: OpenClaimReasonFormFieldsProps) => {
  const { t } = useTranslation();

  const form = useFormContext<OpenClaimForm>();

  form.watch('reason');

  return (
    <>
      <h4 className="mb-4 font-semibold text-gray-700">
        {t('claim.open_claim.reason')}
      </h4>
      <FormField
        control={form.control}
        name="reason"
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormItem>
            <FormLabel className="sr-only" hidden>
              {t('claim.open_claim.fields.claim_reason.label')}
            </FormLabel>
            <FormControl>
              <RadioGroup
                value={value}
                onValueChange={(value) => onChange(value as ClaimReason)}
                className="gap-4"
              >
                {options.map((option) => (
                  <div
                    className={cn('group flex items-center space-x-3', {
                      'items-start': option.description,
                    })}
                    key={option.value}
                  >
                    <RadioGroupItem
                      id={`reason-option-${option.value}`}
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                    />

                    <Label
                      htmlFor={`reason-option-${option.value}`}
                      className="cursor-pointer text-sm text-gray-700 group-has-[.peer:disabled]:cursor-default group-has-[.peer:disabled]:text-gray-300 group-has-[.peer[data-state=checked]]:text-gray-900"
                    >
                      <p>{option.text}</p>
                      {option.description ? (
                        <p className="text-gray-500 group-has-[.peer:disabled]:text-gray-300">
                          {option.description}
                        </p>
                      ) : null}
                    </Label>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage match="required">
              {t(
                'claim.open_claim.fields.claim_reason.validation_errors.required'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
    </>
  );
};
